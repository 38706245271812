import React,{useEffect, useState} from 'react'
import { fetchAPI } from '../utils/FetchApi';
import { FetchMethods } from '../interface/Enums/methods.enum';
const Expiration = () => {
    const token = localStorage.getItem("token") || "";
    const [expirace, setExpirace] = useState<string>("Check expiration");
    const getExpiration = async () => {
      const response:number = await fetchAPI("/auth/expiration", FetchMethods.POST, { token });
      //Translate 
   setExpirace(`Login expires in ${response} minutes`);
    }

    useEffect(()=>{
      const timer = setTimeout(() => {
       setExpirace("Check expiration");
      }, 3000);
      return () => clearTimeout(timer);
    },[expirace])
  return (
    <div>
      <div className="btn btn-sm btn-primary" onClick={()=>{
        getExpiration()
      }}>{expirace}</div>
    </div>
  )
}

export default Expiration