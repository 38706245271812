import { FC, useState, useEffect } from "react";
import { IBidirectionalTemplate } from "../../interface/Bidirectional/interface";
import { fetchTemplatesByUser } from "../../APIs/bidirectional";
import { AccordionComp, H3 } from "../../components/StyledComponents";
import { P, BtnDelete } from "../../components/StyledComponents";
import { deleteTemplate } from "../../APIs/bidirectional";
import { useNavigate } from "react-router-dom";
interface Props {
  refetch?: boolean;
  setRefetch?: () => void;
  setUpdate?: (template: IBidirectionalTemplate) => void;
}
const TemplateList: FC<Props> = ({ refetch, setRefetch, setUpdate }) => {
  const [templates, setTemplates] = useState<IBidirectionalTemplate[]>([]);
  const navigation = useNavigate();
  useEffect(() => {
    (async () => {
      const res = await fetchTemplatesByUser();
      setTemplates(res);
    })();
  }, [refetch]);

  return (
    <>
      {templates.length === 0 && (
        <div className="col-span-12">
          <H3>No templates yet!</H3>
        </div>
      )}
      {templates.length > 0 && (
        <div className="col-span-12">
          {templates.map((template: IBidirectionalTemplate, index: number) => (
            <AccordionComp
              key={index}
              title={template.name}
              className="bg-gray-100 p-2 my-2"
            >
              <P>
                <strong>Description: </strong>
                {template.description} <br />
              </P>
              <P>
                <strong>Questions: </strong> <br />
                <ul>
                  {template.questions.length > 0 &&
                    template.questions.map((question, index) => (
                      <li key={index}>
                        - {question.question} <small>({question.type})</small>
                      </li>
                    ))}
                </ul>
              </P>
              <P>
                <strong>Who will be evaluated: </strong> <br />
                <ul>
                  {template.whoWillBeEvaluated.length > 0 &&
                    template.whoWillBeEvaluated.map((user, index) => (
                      <li
                        onClick={() => {
                          navigation(
                            `/bidirectional-evaluation/${
                              typeof user !== "string" && user._id
                            }`
                          );
                        }}
                        className="dai-badge dai-badge-secondary font-bold mr-2"
                        key={index}
                      >
                        {typeof user !== "string" && user.name}{" "}
                        {typeof user !== "string" && user.surname}
                      </li>
                    ))}
                </ul>
              </P>
              <P>
                <strong>
                  Who can evaluate{" "}
                  {template.whoCanEvaluate.length > 0 && (
                    <small>({template.whoCanEvaluate?.length})</small>
                  )}
                  :{" "}
                </strong>{" "}
                <br />
                <ul>
                  {template.whoCanEvaluate.length > 0 &&
                    template.whoCanEvaluate.map((user, index) => (
                      <li
                        className="dai-badge dai-badge-primary font-bold"
                        key={index}
                      >
                        {typeof user !== "string" && user.name}{" "}
                        {typeof user !== "string" && user.surname}
                      </li>
                    ))}
                </ul>
              </P>
              <P>
                <strong>Expiration date: </strong>
                {template.expiration && template.expiration === "never-expires"
                  ? template.expiration
                  : typeof template.expiration === "string"
                  ? new Date(template.expiration).toDateString()
                  : template.expiration?.toLocaleDateString()}
              </P>
              <P>
                {
                    template.relationship === "1:N" && <strong>
                    You already evaluated: {" "}
                    {template.whoAlreadyEvaluated &&
                      template.whoAlreadyEvaluated.length > 0 && (
                        <small>({template.whoAlreadyEvaluated?.length})</small>
                      )}
                    :{" "}
                  </strong>
                }
                {
                    template.relationship === "N:1" && <strong>
                    Evaluated by: {" "}
                    {template.whoAlreadyEvaluated &&
                      template.whoAlreadyEvaluated.length > 0 && (
                        <small>({template.whoAlreadyEvaluated?.length})</small>
                      )}
                    :{" "}
                  </strong>
                }
                
                {template.whoAlreadyEvaluated &&
                  template.whoAlreadyEvaluated.length > 0 &&
                  template.whoAlreadyEvaluated.map((user, index) => (
                    <span
                      key={index}
                      className="dai-badge dai-badge-success font-bold text-white mr-2"
                    >
                      {typeof user !== "string" && user.name}{" "}
                      {typeof user !== "string" && user.surname}
                    </span>
                  ))}
              </P>
              <P>
                <strong>Relationship: </strong>
                <small>
                  {template.relationship === "1:N"
                    ? "(1:n) - One manager can evaluate anybody"
                    : "(n:1) - Colleagues can evaluate one manager"}
                </small>
              </P>
              <hr />
              {template._id && (
                <div className="flex flex-row gap-2">
                  <BtnDelete
                    onClick={async () => {
                      const confirm = window.confirm(
                        "Are you sure you want to delete this question?"
                      );
                      if (!confirm) {
                        return;
                      }
                      if (!template._id) {
                        return;
                      }
                      await deleteTemplate(template._id);
                      setRefetch && setRefetch();
                    }}
                  >
                    Delete
                  </BtnDelete>
                  {/* 
                                <BtnUpdate onClick={() => {
                                    setUpdate && setUpdate(template);
                                }}>Update</BtnUpdate> */}
                </div>
              )}
            </AccordionComp>
          ))}
        </div>
      )}
    </>
  );
};

export default TemplateList;
