import { FC, useEffect, useState } from "react";
import { fetchUsersByMaster, fetchAllUsersByCountry } from "../../APIs/user";
import CountryFilter from "../CountryFilter";
import { AccordionComp, P, Grid12 } from "../../components/StyledComponents";
import { BsPlusCircleFill, BsTrashFill } from "react-icons/bs";
import { IUser } from "../../interface/IUser.interface";

interface Props {
  users: IUser[];
  setUsers: (users: IUser[]) => void;
}
const UserPicker: FC<Props> = ({ users, setUsers }) => {
  const [country, setCountry] = useState("");

  const fetchAllAvailableQuestions = async () => {
    const res = await fetchUsersByMaster();
    setUsers(res);
  };

  useEffect(() => {
    fetchAllAvailableQuestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Adds a question to the selected questions and removes it from the available questions
  const addUser = (index: number) => {
    //Modify the attribute "selected" to true
    users[index].selected = true;
    //Add the question to the selected users
    setUsers([...users]);
  };

  const removeUser = (index: number) => {
    //Modify the attribute "selected" to false
    users[index].selected = false;
    //Remove the question from the selected users
    setUsers([...users]);
  };

  const loadAllUsersByCountry = async (country: string) => {
    const res = await fetchAllUsersByCountry(country);
    setUsers(res);
  };

  return (
    <>
      <div className="w-full max-w-xl">
        <Grid12 className="shadow-md rounded p-1 mb-4">
          <CountryFilter returnedCountry={setCountry} className="col-span-6 hidden" showHeading={false}/>
          <div
            className="dai-btn dai-btn-warning col-span-6 dai-btn-sm"
            onClick={() => {
              loadAllUsersByCountry(country);
            }}
          >
            Load All users
          </div>
          <div
            className="dai-btn dai-btn-info col-span-6 text-white dai-btn-sm"
            onClick={() => {
              fetchAllAvailableQuestions();
            }}
          >
            Load only your users
          </div>
        </Grid12>
      </div>

      {/* OPEN QUESTIONS LIST */}
      {users.length > 0 &&
        users.map((user: IUser, index: number) => (
          <Grid12>
            <AccordionComp
              key={index}
              title={`${user.name} ${user.surname}`}
              className={`bg-gray-100 my-1 col-span-10 ${
                user.selected && "bg-green-200"
              }`}
            >
              <P>
                <strong>Department: </strong>
                {user.department?.name} <br />
                <strong>Position: </strong>: {user.jobName?.name} <br />
              </P>
              <hr />
              <P>
                <small>
                  <strong>Created: </strong>
                  {user.createdAt && user.createdAt.toString()} <br />
                </small>
              </P>
            </AccordionComp>
            {user.selected ? (
              <div
                onClick={() => {
                  removeUser(index);
                }}
                className="btn text-4xl self-center text-red-500"
              >
                <BsTrashFill />
              </div>
            ) : (
              <div
                onClick={() => {
                  addUser(index);
                }}
                className="btn text-4xl self-center text-green-500"
              >
                <BsPlusCircleFill />
              </div>
            )}
          </Grid12>
        ))}
    </>
  );
};

export default UserPicker;
