import { FC } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { IRangeQuestion } from '../../interface/Bidirectional/interface';

// Register required components for Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface Props {
  questions: IRangeQuestion[];
}

const RangeQuestionChart: FC<Props> = ({ questions }) => {
  const data = {
    labels: questions.map((q) => q.question),
    datasets: [
      {
        label: 'Answers',
        data: questions.map((q) => Number(q.answer)), // Convert answers to numbers
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
      title: {
        display: true,
        text: 'Range Question Chart',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        suggestedMax: 5, // Assuming max rating is 5
      },
    },
  };

  return (
    <div className='w-full min-h-[500px] mx-auto self-center p-10'>
      <Bar data={data} options={options} />
    </div>
  );
};

export default RangeQuestionChart;
