import { UserInterface } from "../../interface";
import {
  BsThreeDotsVertical,
  BsFillEyeFill,BsFillPenFill ,
} from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { Accordion, Dropdown } from "react-bootstrap";


const UserDetail = ({ users }: { users: UserInterface[] }) => {
  const navigate = useNavigate();
  return (
    <div className="container">
        <div className="row">
                  {users && users.map((u: UserInterface, i: number) => (
                    <div className="row justify-content-between border p-2 m-2" key={i}>
                    <div className="col-sm-2 align-self-start">
                      <Dropdown>
                        <Dropdown.Toggle size="sm" variant="info">
                          <BsThreeDotsVertical />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            className="text-primary flex flex-row gap-2 align-center items-center"
                            onClick={() => {
                              navigate(`/user/detail/${u._id}`);
                            }}
                          >
                            <BsFillEyeFill /> View Appraisal history
                          </Dropdown.Item>

                          <Dropdown.Item
             className="text-primary flex flex-row gap-2 align-center items-center"
                            onClick={() => {
                              navigate(`/admin-colleague-evaluation/${u._id}`);
                            }}
                          >
                            <BsFillPenFill /> Appraisal Evaluation
                          </Dropdown.Item>
                          <Dropdown.Item
                    className="text-primary flex flex-row gap-2 align-center items-center"
                            onClick={() => {
                              navigate(`/user/anual-evaluation-detail/${u._id}`);
                            }}
                          >
                            <BsFillEyeFill /> View Annual Evaluation history
                          </Dropdown.Item>
                       
                          <Dropdown.Item
                       className="text-primary flex flex-row gap-2 align-center items-center"
                            onClick={() => {
                              navigate(`/admin-create-new-annual-summary/${u._id}`);
                            }}
                          >
                            <BsFillPenFill /> Annual Evaluation
                          </Dropdown.Item>
                          <Dropdown.Item
                       className="text-primary flex flex-row gap-2 align-center items-center"
                            onClick={() => {
                              navigate(`/bidirectional-evaluation/${u._id}`);
                            }}
                          >
                            <BsFillPenFill /> Bidirectional Evaluation
                          </Dropdown.Item>
                          <Dropdown.Item
                       className="text-primary flex flex-row gap-2 align-center items-center"
                            onClick={() => {
                              navigate(`/bidirectional-history/${u._id}`);
                            }}
                          >
                            <BsFillPenFill /> Bidirectional Evaluation History
                          </Dropdown.Item>
                         
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className="col-lg-10">
                      <Accordion className="rounded m-2">
                        <Accordion.Header>
                           <strong>{u.name} {u.surname}</strong> 
                        </Accordion.Header>
                        <Accordion.Body>
                           Email: {u.email} <br />
                           Department: {u.department?.name}<br />
                           Jobname: {u.jobName?.name}<br />
                        
                          </Accordion.Body>
                      </Accordion>
                      </div>
                      </div>
                  ))}
                  </div>
    </div>
  );
};

export default UserDetail;
