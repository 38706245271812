import { useState, useEffect } from 'react'
import { FC } from 'react'
import { IUser } from '../../interface/IUser.interface'
import { H2, H3} from '../../components/StyledComponents'
import { getMasterByUserId } from '../../APIs'
import { useNavigate } from 'react-router-dom'
import RGManagers from "../../assets/rg_managers.png"

const AssignedManager: FC = () => {
    const [master, setMaster] = useState<IUser | null>(null);
    const navigate = useNavigate()

    useEffect(() => {
        (async () => {
            try {
                const data = await getMasterByUserId();
                setMaster(data)
            } catch (error: any) {
                console.error(error)
            }
        })()
    }, [])
    return (<>
        {
            master &&
            <div className="dai-card dai-card-side bg-base-100 shadow-sm">
                <figure>
                    <img
                        src={RGManagers}
                        alt="Richter Gedeon Managers"
                        className='max-w-sm w-[230px]'
                        />
                </figure>
                <div className="dai-card-body">
                    <small>MANAGER:</small>
                    <H2 className="dai-card-title">{`${master.name} ${master.surname}`}</H2>
                        <small className='text-sm text-gray-500 italic'>{master.department?.name}</small>
                    <div className="dai-card-actions justify-start flex-col">
                        <H3>List of actions</H3>
                        <ul className='list-disc'>
                            <li className='dai-badge dai-badge-info text-white py-2 cursor-pointer' onClick={()=>{
                                navigate(`/bidirectional-evaluation/${master._id}`)
                            }}>Check available evaluations for your manager</li>
                        </ul>
                    </div>
                </div>
            </div>
        }

    </>

    )
}

export default AssignedManager