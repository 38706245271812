import { IRangeQuestion } from "../interface";
export const EmptyRangeQuestion: IRangeQuestion = {
    question: "",
    description: "",
    min: 0,
    max:  0,
    type: "range",
    answer: 0,
    creator: "",
    createdAt: "",
    updatedAt: "",
    selected: false,
};