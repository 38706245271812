import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminContainer from "../../../admin-components/AdminContainer";
import {
  UserInterface,
  GlobalScope,
  AppraisalDocument,
  IEvaluationDynamicDocument,
  IPerformanceMetricInput,
  IPerformanceMetricOutput,
} from "../../../interface";
import { Accordion, Badge } from "react-bootstrap";
import { IUser } from "../../../interface/IUser.interface";
import AppraisalDocumentSelection from "./ColleageEvaluationComponents/AppraisalDocumentSelection";
import RatingDistribution from "./ColleageEvaluationComponents/RatingDistribution";
import { setError } from "../../../store/reducers/errorReducer";
import { emptyEvaluationDynamicDocument } from "./ColleageEvaluationComponents/EmptyEvaluationDocument";
import { ValidateEvaluationDynamicDocument } from "../../../utils/ValidateEvaluationDynamicDocuments";
import CompetenciesChart from "../../../components/Charts/Competencies.chart";
import EnergizerAssesmentChart from "../../../components/Charts/EnergizerAssesment.chart";
import InputOutputChart from "../../../components/Charts/InputOutput.chart";
import { fetchAPI } from "../../../utils/FetchApi";
import { FetchMethods } from "../../../interface/Enums/methods.enum";
import EvaluationDocumentMasterComponents from "./ColleageEvaluationComponents/EvaluationDocumentMasterComponents";
import { useNavigate, useParams } from "react-router-dom";
import { IError } from "../../../interface";
import LoadDrafts from "./ColleageEvaluationComponents/LoadDrafts";
const AppraisalColleagueEvaluation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data: UserInterface = useSelector((d: GlobalScope): UserInterface => {
    return d.auth.user;
  });
  const [previsouEvalDynamicDocuments, setPrevisouEvalDynamicDocuments] =
    useState<IEvaluationDynamicDocument[]>([]);
  const [selectedEvaluationComponent, setSelectedEvaluationComponent] =
    useState<IEvaluationDynamicDocument>(emptyEvaluationDynamicDocument);
  const [evalDynamicDocument, setEvalDynamicDocument] =
    useState<IEvaluationDynamicDocument>(emptyEvaluationDynamicDocument); //mono fucking state

  const selectAppraisalDocument = (app: AppraisalDocument) => {
    //@ts-ignore
    setEvalDynamicDocument({ ...evalDynamicDocument, appraisalDocument: app });
  };

  //Preload calculations - ok
  useEffect(() => {
    if (evalDynamicDocument?.appraisalDocument) {
      //gets sum of energizer inputs for further calculation
      const inputImportanceEnergizerSum: number | undefined =
        evalDynamicDocument?.appraisalDocument?.inputs.reduce(
          (fin: number, obj: IPerformanceMetricInput) => {
            if (obj.type === "energizer") {
              return (fin += obj.importance);
            }
            return fin;
          },
          0
        );

      //Gets of standard outputs as well (so I do not have shitload of effects)
      const outputImportanceSum: number | undefined =
        evalDynamicDocument?.appraisalDocument?.outputs.reduce(
          (fin: number, obj: IPerformanceMetricOutput) => {
            return (fin += obj.importance);
          },
          0
        );
      //Here I have to create new properties for the given object
      setEvalDynamicDocument((p) => ({
        ...p,
        energizerImportanceSum: inputImportanceEnergizerSum,
        inputImportanceSum: 100 - inputImportanceEnergizerSum,
        outputStandardImportanceSum: outputImportanceSum,
      }));
    }
  }, [evalDynamicDocument?.appraisalDocument]);

  useEffect(() => {
    if (evalDynamicDocument?.inputsEnergizer) {
      let sum: number = 0;
      for (let key in evalDynamicDocument.inputsEnergizer) {

        const {

          importance,
          //@ts-ignore
          fulfillment,
        }: { importance: number; fullfillment: number } =
          //@ts-ignore
          evalDynamicDocument.inputsEnergizer[key];
        //@ts-ignore
        sum += parseInt(importance * fulfillment);
      }
      setEvalDynamicDocument((e) => ({
        ...e,
        inputEnergizerSum: sum,
        energizerInputFinalValue:
          sum / evalDynamicDocument.energizerImportanceSum,
      }));
    }
  }, [
    evalDynamicDocument?.inputsEnergizer,
    evalDynamicDocument.energizerImportanceSum,
  ]);
  //I validate only Standard inputs{
  useEffect(() => {
    if (evalDynamicDocument?.inputsStandard) {
      let sum: number = 0;
      for (let key in evalDynamicDocument.inputsStandard) {

        const {
          importance,
          //@ts-ignore
          fulfillment,
        }: { importance: number; fullfillment: number } =
          //@ts-ignore
          evalDynamicDocument.inputsStandard[key];
        //@ts-ignore
        sum += parseInt(importance * fulfillment);
      }
      setEvalDynamicDocument((e) => ({
        ...e,
        inputStandardSum: sum,
      }));
    }
  }, [evalDynamicDocument?.inputsStandard]);

  //Overall input value
  useEffect(() => {
    const finalNumber: number =
      (evalDynamicDocument?.inputEnergizerSum +
        evalDynamicDocument?.inputStandardSum) /
      (evalDynamicDocument?.energizerImportanceSum +
        evalDynamicDocument?.inputImportanceSum);
    finalNumber.toFixed(2);
    if (finalNumber) {
      setEvalDynamicDocument((e) => ({
        ...e,
        overallInputFinalvalue: finalNumber,
      }));
    }
  }, [
    evalDynamicDocument?.inputStandardSum,
    evalDynamicDocument?.energizerImportanceSum,
    evalDynamicDocument?.inputEnergizerSum,
    evalDynamicDocument?.inputImportanceSum,
  ]);

  //Lets create a logic for alphabet evaluation
  useEffect(() => {
    if (evalDynamicDocument?.appraisalDocument.ratingDistribution) {
      const data: any =
        evalDynamicDocument?.appraisalDocument.ratingDistribution;

      const arr: any = [];

      for (let keys in data) {
        arr.push(data[keys]);
      }

      const correctRangeObject = arr.filter((d: any) => {
        //tady je problém
        return (
          evalDynamicDocument?.energizerInputFinalValue <= d.from + 0.99 &&
          evalDynamicDocument.energizerInputFinalValue >= d.to - 0.99
        );
      });
      if (correctRangeObject.length > 0) {
        setEvalDynamicDocument((e) => ({
          ...e,
          energizerInputLetter: correctRangeObject[correctRangeObject.length - 1].letter,
        }));
      }
    }
  }, [
    evalDynamicDocument?.energizerInputFinalValue,
    evalDynamicDocument?.appraisalDocument.ratingDistribution,
  ]);
  //Lets create a logic for alphabet evaluation
  useEffect(() => {
    if (evalDynamicDocument?.appraisalDocument.ratingDistribution) {
      const data: any =
        evalDynamicDocument?.appraisalDocument.ratingDistribution;
      const arr: any = [];
      for (let keys in data) {
        arr.push(data[keys]);
      }
      const correctRangeObject = arr.filter((d: any) => {

        return (
          evalDynamicDocument?.overallInputFinalvalue <= d.from + 0.99 &&
          evalDynamicDocument?.overallInputFinalvalue >= d.to - 0.99
        );
      });

      if (correctRangeObject.length > 0) {
        setEvalDynamicDocument((e) => ({
          ...e,
          overallInputLetter: correctRangeObject[correctRangeObject.length - 1].letter,
        }));
      }
    }
  }, [
    evalDynamicDocument?.overallInputFinalvalue,
    evalDynamicDocument?.appraisalDocument.ratingDistribution,
  ]);

  /**
   * OUTPUTS
   */
  //If anything in the standard outputs happens, the virtual DOM recalculates everything
  useEffect(() => {
    //Counts the correct percentage
    if (evalDynamicDocument?.outputsStandard) {
      let sum: number = 0;
      for (let key in evalDynamicDocument.outputsStandard) {
        //@ts-ignore
        const {
          importance,
          //@ts-ignore
          fulfillment,
        }: { importance: number; fullfillment: number } =
          //@ts-ignore
          evalDynamicDocument.outputsStandard[key];
        //@ts-ignore
        sum += parseInt(importance * fulfillment);
      }
      setEvalDynamicDocument((e) => ({
        ...e,
        outputStandardSum: sum,
        outputStandardFinalValue:
          sum / evalDynamicDocument.outputStandardImportanceSum,
      }));
    }
  }, [
    evalDynamicDocument?.outputsStandard,
    evalDynamicDocument.outputStandardImportanceSum,
  ]);

  //Lets create a logic for alphabet evaluation
  useEffect(() => {
    if (evalDynamicDocument?.appraisalDocument.ratingDistribution) {
      const data: any =
        evalDynamicDocument?.appraisalDocument.ratingDistribution;
      const arr: any = [];
      for (let keys in data) {
        arr.push(data[keys]);
      }
      const correctRangeObject = arr.filter((d: any) => {
        return (
          evalDynamicDocument?.outputStandardFinalValue <= d.from + 0.99 &&
          evalDynamicDocument?.outputStandardFinalValue >= d.to - 0.99
        );
      });

      if (correctRangeObject.length > 0) {
        setEvalDynamicDocument((e) => ({
          ...e,
          outputStandardLetter: correctRangeObject[correctRangeObject.length - 1].letter,
        }));
      }
    }
  }, [
    evalDynamicDocument?.outputStandardFinalValue,
    evalDynamicDocument?.appraisalDocument.ratingDistribution,
  ]);

  /**
   * Competencies
   */

  //Set letter based on competencies
  useEffect(() => {
    //2. Map through the competencies to get the values
    const arrayOfCompetencyLetters: string[] = [];
    if (evalDynamicDocument?.competencies) {
      for (let keys in evalDynamicDocument?.competencies) {
        arrayOfCompetencyLetters.push(
          //@ts-ignore
          evalDynamicDocument?.competencies[keys].evaluation
        );
      }
    }

    const turnCompetencyLettersIntoValues: number[] =
      arrayOfCompetencyLetters.map((letter: string) => {
        if (letter !== "X") {

          const fromValue =
            //@ts-ignore
            evalDynamicDocument?.appraisalDocument.ratingDistribution[
              `${letter.toLowerCase()}`
            ].from;
          const toValue =
            //@ts-ignore
            evalDynamicDocument?.appraisalDocument.ratingDistribution[
              `${letter.toLowerCase()}`
            ].to;
          return (fromValue + toValue) / 2;
          //@ts-ignore
          //return evalDynamicDocument?.appraisalDocument.ratingDistribution[`${letter.toLowerCase()}`].from
        } else {
          return 0;
        }
      });
    const averageFromCompetencyLetters: number =
      turnCompetencyLettersIntoValues.reduce(
        (p: number, c: number) => p + c,
        0
      );
    //the average

    const theFinalCompetencyScore: number =
      averageFromCompetencyLetters / turnCompetencyLettersIntoValues.length;

    // aaaand this fucking sh1t is the final move how to categorize the final score

    const data: any = evalDynamicDocument?.appraisalDocument.ratingDistribution;
    const arr: any = [];
    for (let keys in data) {
      arr.push(data[keys]);
    }
    const correctRangeObject = arr.filter((d: any) => {
      return (
        theFinalCompetencyScore <= d.from + 0.99 &&
        theFinalCompetencyScore >= d.to - 0.99
      );
    });

    if (correctRangeObject.length > 0) {
      setEvalDynamicDocument((e) => ({
        ...e,
        competenciesLetter: correctRangeObject[0].letter,
        competenciesFinaPercentageNumber: theFinalCompetencyScore,
      }));
    }
  }, [
    evalDynamicDocument?.competencies,
    evalDynamicDocument?.appraisalDocument.ratingDistribution,
  ]);

  const saveEvaluationForGivenColleague = async () => {
    ValidateEvaluationDynamicDocument(
      evalDynamicDocument,
      async (response: { status: boolean; msg: string }) => {
        if (!response.status) {
          dispatch(setError(response.msg));
        } else {
          //this is just for easier queries
          evalDynamicDocument.country = evalDynamicDocument.selectedRep.country;
          evalDynamicDocument.appraisalPeriod =
            evalDynamicDocument.appraisalDocument.evaluationPeriod;
          evalDynamicDocument.repNameQuery = `${evalDynamicDocument.selectedRep.name} ${evalDynamicDocument.selectedRep.surname}`;

          //Save the doc

          try {
            const message: Response = await fetchAPI(
              `/admincmds/create-evaluation-document`,
              FetchMethods.POST,
              evalDynamicDocument
            );
            console.log(message)
            if (message.hasOwnProperty("error")) {
              //@ts-ignore
              throw new Error(message.message.toString());
            }
            setEvalDynamicDocument(emptyEvaluationDynamicDocument);
            navigate(`/user/detail/${id}`);
          } catch (error: any) {
            dispatch(setError(error.msg.toString()));
          }
        }
      }
    );
  };

  //Better behavior
  const { id } = useParams();
  useEffect(() => {
    (async () => {
      try {
        const data: IUser & IError = await fetchAPI(
          `/users/${id}`,
          FetchMethods.GET
        );

        if (data.error) {
          throw new Error(data.message?.toString());
        }

        setEvalDynamicDocument((e) => ({
          ...e,
          selectedRep: data,
        }));

        const evalDocs: IEvaluationDynamicDocument[] = await fetchAPI(
          "/admincmds/get-users-evaluation-document",
          FetchMethods.POST,
          data
        );
        if (evalDocs.length > 0) {
          setPrevisouEvalDynamicDocuments(evalDocs);
        }
      } catch (error: any) {
        dispatch(setError(error.msg.toString()));
      }
    })();

    return () => { };
  }, [id, dispatch]);

  // Save Draft Mechanism
  const [draftName, setdraftName] = useState<string>("");
  const [reloadDraftEverything, setReloadDraftEverything] = useState<boolean>(false);


  useEffect(() => {
    setdraftName("");
  }, [reloadDraftEverything])

interface ErrorMessage{
  message:string,
  type: "danger" | "success"
}
  const emptyErrorMessage: ErrorMessage= {
    message: "",
    type: "danger",
  }
  const [draftErrorMessage, setDraftErrorMessage] = useState<ErrorMessage>(emptyErrorMessage);
  const saveDraft = async () => {

    if (draftName.length < 1) {
      setDraftErrorMessage({
        message: "Draft name can not be empty",
        type: "danger"
      });
      return;
    }
    const newDraft = {
      ...evalDynamicDocument,
      draftName: draftName,
      creator: data._id
    }

    console.log(newDraft)

    const saveItToTheServer: any = await fetchAPI(`/admincmds/create-draft-evaluation-document/${data._id}`, FetchMethods.POST, newDraft);

    if (saveItToTheServer.hasOwnProperty("message")) {
      setDraftErrorMessage({
        message: saveItToTheServer.message.message.toString(),
        type: "danger"
      })
      return;
    }

    setDraftErrorMessage({
      message: "Draft was saved",
      type: "success"
    })

    setdraftName("");
    setReloadDraftEverything(!reloadDraftEverything);
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setDraftErrorMessage(emptyErrorMessage);
    }, 3000);
    return () => clearTimeout(timer);
  }, [draftErrorMessage.message])
  return (
    <AdminContainer>
      <div className="row justify-content-md-center">
        <div className="col"><h3 className="h3">Hi {data.name && data.name}</h3></div>
        <div className="col">
          {/* <div className="btn btn-success btn-sm m-2" onClick={()=>{
            console.log(evalDynamicDocument)
          }}>Show the current document</div> */}
          <Accordion className="border">
            <Accordion.Header>
             Manage Drafts
            </Accordion.Header>
            <Accordion.Body>
              <h2>Create draft</h2>
              <div className="row">
                <div className="col">
                  <input type="text" className="form-control" placeholder="Draft name" onChange={({ target }) => setdraftName(target.value)} value={draftName} />
                </div>
                <div className="col">
                  <button className="btn btn-primary" onClick={saveDraft}>Create a new draft</button>
                </div>
              </div>
              <hr className="hr" />
              <br />
              <div className="row">
                {
                  id && <LoadDrafts
                    id={data._id} // my own ID
                    feedbackMessage={(f:ErrorMessage)=>{
                      setDraftErrorMessage(f);
                    }}
                    repid={id} // From useParams

                    currentDocument={evalDynamicDocument} //This return bad ID imho
                    reloadbyParent={reloadDraftEverything}
               
                    selected={(evalDocument: any) => {
                      setEvalDynamicDocument(evalDocument);

                    }} />
                }

              </div>
              <br />
              {draftErrorMessage.message.length > 0 && <div className={`alert ${draftErrorMessage.type === "danger" ? "alert-danger" : "alert-success"}`}>{draftErrorMessage.message}</div>}
            </Accordion.Body>
          </Accordion>
          <br />
        </div>
      </div>
      <br />
      {selectedEvaluationComponent.repNameQuery.length > 0 && (
        <EvaluationDocumentMasterComponents
          bgcolor="white"
          width={"30vw"}
          position="fixed"
          evaldoc={selectedEvaluationComponent}
        />
      )}
      <Accordion className="border">
        <Accordion.Header>
          <Badge className="bg-success m-2" style={{ borderRadius: "50%" }}>
            1
          </Badge>{" "}
          Period selection for {evalDynamicDocument.selectedRep.name}{" "}
          {evalDynamicDocument.selectedRep.surname}
        </Accordion.Header>
        <Accordion.Body>




          <div className="row">

            {evalDynamicDocument?.selectedRep && (
              <div className="col p-3 border bg-light m-2 rounded">
                <div className="row">
                  <h6 className="h6">Period selection </h6>
                  <AppraisalDocumentSelection
                    selectedAppraisalDocument={selectAppraisalDocument}
                    previousPeriods={previsouEvalDynamicDocuments}
                    currentDocument={evalDynamicDocument}
                  />
                </div>
              </div>
            )}



            {previsouEvalDynamicDocuments.length > 0 && (
              <div className="col p-3 border bg-light m-2 rounded">
                <h6 className="h6">Load historical evaluations for <span className="text-danger">{evalDynamicDocument.selectedRep.name}{" "}
                  {evalDynamicDocument.selectedRep.surname}</span></h6>
                <select
                  className="form-control"
                  onChange={async ({ target }) => {
                    if (target.value !== "---") {
                      try {
                        const doc: IEvaluationDynamicDocument = await fetchAPI(
                          `/admincmds/get-evaluation-document/${target.value}`,
                          FetchMethods.GET
                        );
                        doc.startDate = new Date(doc.startDate);
                        doc.currentPositionHeld = new Date(
                          doc.currentPositionHeld
                        );
                        doc.appraisalDate = new Date(doc.appraisalDate);
                        doc.dateConfirmation = new Date();
                        setSelectedEvaluationComponent(doc);
                      } catch (error) {
                        setSelectedEvaluationComponent(
                          emptyEvaluationDynamicDocument
                        );
                      }
                    } else {
                      setSelectedEvaluationComponent(
                        emptyEvaluationDynamicDocument
                      );
                    }
                  }}
                >
                  <option value="---">---</option>
                  {previsouEvalDynamicDocuments.map(
                    (e: IEvaluationDynamicDocument) => (
                      <option className="form-control" key={e._id} value={e._id}>
                        {e.appraisalPeriod} -{" "}
                        {new Date(e.appraisalDate).toLocaleDateString()}
                      </option>
                    )
                  )}
                </select>
              </div>
            )}

          </div>
        </Accordion.Body>
      </Accordion>
      {evalDynamicDocument?.appraisalDocument &&
        evalDynamicDocument?.appraisalDocument.inputs.length > 0 && (
          <Accordion className="border mt-2">
            <Accordion.Header>
              <span>
                {" "}
                <Badge
                  className="bg-success m-2"
                  style={{ borderRadius: "50%" }}
                >
                  2
                </Badge>{" "}
                Time to take care of inputs, outputs and much more for{" "}
                <strong style={{ color: "blueviolet" }}>
                  {" "}
                  {evalDynamicDocument?.selectedRep?.name &&
                    evalDynamicDocument?.selectedRep?.name}{" "}
                  {evalDynamicDocument?.selectedRep?.surname &&
                    evalDynamicDocument?.selectedRep?.surname}
                </strong>
              </span>
            </Accordion.Header>
            <Accordion.Body>
              <div className="row shadow rounded p-4">
                <h4 className="h6 small">Rating distribution info</h4>
                <RatingDistribution
                  data={
                    evalDynamicDocument?.appraisalDocument.ratingDistribution
                  }
                />
              </div>

              {
                // INPUTS
              }
              <Accordion className="shadow roudned p-2 m-2">
                <Accordion.Header>Inputs</Accordion.Header>
                <Accordion.Body>
                  <h6 className="h6p-2 m-2">
                    {evalDynamicDocument?.appraisalDocument.inputName}
                  </h6>
                  <p className="p-2 m-2">
                    {evalDynamicDocument?.appraisalDocument.inputDescription}
                  </p>
                  <table className="table table-bordered p-2 m-2">
                    <tbody>
                      <tr>
                        <th>Performance inputs</th>
                        <th>Exaplanation</th>
                        <th>Importance (%)</th>
                        <th>Fulfillment (%)</th>
                      </tr>

                      {evalDynamicDocument?.appraisalDocument.inputs.map(
                        (input, i) =>
                          input.type === "energizer" && (
                            <tr key={i}>
                              <td>{input.name}</td>
                              <td>
                                {input.explanation}{" "}
                                <Badge className="bg-warning small">
                                  {input.type}
                                </Badge>
                              </td>
                              <td className="text-center">
                                {input.importance} %
                              </td>
                              <td>
                                <input
                                  min={0}
                                  max={100}
                                  defaultValue={""}
                                  className="form-control"
                                  type="number"
                                  value={
                                    //@ts-ignore
                                    evalDynamicDocument?.inputsEnergizer && evalDynamicDocument?.inputsEnergizer[i]?.fulfillment}
                                  onChange={({ target }) => {
                                    //target.value can not be higher than 100 && lower than 0
                                    if (parseInt(target.value) <= 100 && parseInt(target.value) >= 0) {
                                      const importance = input.importance;
                                      const type = input.type;
                                      const category = input.category;
                                      const name = input.name;
                                      //@ts-ignore
                                      setEvalDynamicDocument({
                                        ...evalDynamicDocument,
                                        inputsEnergizer: {
                                          ...evalDynamicDocument?.inputsEnergizer,
                                          [i]: {
                                            fulfillment: parseInt(target.value),
                                            importance,
                                            type,
                                            category,
                                            name,
                                          },
                                        },
                                      });
                                    }


                                  }}
                                />
                              </td>
                            </tr>
                          )
                      )}

                      {evalDynamicDocument?.appraisalDocument.inputs.map(
                        (input, i) =>
                          input.type !== "energizer" && (
                            <tr key={i}>
                              <td>{input.name}</td>
                              <td>
                                {input.explanation}{" "}
                                <Badge className="bg-info small">
                                  {input.type}
                                </Badge>
                              </td>
                              <td className="text-center">
                                {input.importance} %
                              </td>
                              <td>
                                <input
                                  min={0}
                                  max={100}
                                  defaultValue={""}
                                  className="form-control"
                                  type="number"
                                  name={`input${i}`}
                                  value={
                                    //@ts-ignore
                                    evalDynamicDocument?.inputsStandard && evalDynamicDocument?.inputsStandard[i]?.fulfillment
                                  }

                                  onChange={({ target }) => {
                                    //target.value can not be higher than 100 && lower than 0
                                    if (parseInt(target.value) <= 100 && parseInt(target.value) >= 0) {
                                      const importance = input.importance;
                                      const type = input.type;
                                      const category = input.category;
                                      const name = input.name;
                                      //@ts-ignore
                                      setEvalDynamicDocument({
                                        ...evalDynamicDocument,
                                        inputsStandard: {
                                          ...evalDynamicDocument?.inputsStandard,
                                          [i]: {
                                            fulfillment: parseInt(target.value),
                                            importance,
                                            type,
                                            category,
                                            name,
                                          },
                                        },
                                      });
                                    }

                                  }}
                                />
                              </td>
                            </tr>
                          )
                      )}
                      <tr>
                        <td></td>
                        <td>Energizer</td>
                        <td>
                          {evalDynamicDocument?.energizerInputFinalValue &&
                            evalDynamicDocument?.energizerInputFinalValue.toFixed(
                              2
                            )}
                          %
                        </td>
                        <td>
                          {evalDynamicDocument?.energizerInputLetter &&
                            evalDynamicDocument?.energizerInputLetter}
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>Overall rating for inputs (A,B,C,D,E,F)</td>
                        <td>
                          {evalDynamicDocument?.overallInputFinalvalue
                            ? evalDynamicDocument?.overallInputFinalvalue.toFixed(
                              2
                            )
                            : 0}{" "}
                          %
                        </td>
                        <td>
                          {evalDynamicDocument?.overallInputLetter
                            ? evalDynamicDocument?.overallInputLetter
                            : ""}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Accordion.Body>
              </Accordion>
              {
                // Outputs
              }

              <Accordion className="shadow roudned p-2 m-2">
                <Accordion.Header>Outputs</Accordion.Header>
                <Accordion.Body>
                  <h6 className="h6p-2 m-2">
                    {evalDynamicDocument?.appraisalDocument.outputName}
                  </h6>
                  <p className="p-2 m-2">
                    {evalDynamicDocument?.appraisalDocument.outputDescription}
                  </p>
                  <table className="table table-bordered p-2 m-2">
                    <tbody>
                      <tr>
                        <th>Performance outputs</th>
                        <th>Exaplanation</th>
                        <th>Importance (%)</th>
                        <th>Fulfillment (%)</th>
                      </tr>

                      {evalDynamicDocument?.appraisalDocument.outputs.map(
                        (output, i) => (
                          <tr key={i}>
                            <td>{output.name}</td>
                            <td>{output.explanation}</td>
                            <td className="text-center">
                              {output.importance} %
                            </td>
                            <td>
                              <input
                                min={0}
                                max={100}
                                defaultValue={""}
                                className="form-control"
                                type="number"
                                value={
                                  //@ts-ignore
                                  evalDynamicDocument?.outputsStandard && evalDynamicDocument?.outputsStandard[i]?.fulfillment

                                }
                                onChange={({ target }) => {
                                  //target.value can not be higher than 100 && lower than 0
                                  if (parseInt(target.value) <= 100 && parseInt(target.value) >= 0) {
                                    const importance = output.importance;
                                    const type = output.type;
                                    const category = output.category;
                                    const name = output.name;
                                    //@ts-ignore
                                    setEvalDynamicDocument({
                                      ...evalDynamicDocument,
                                      outputsStandard: {
                                        ...evalDynamicDocument?.outputsStandard,
                                        [i]: {
                                          fulfillment: parseInt(target.value),
                                          importance,
                                          type,
                                          category,
                                          name,
                                        },
                                      },
                                    });
                                  }

                                }}
                              />
                            </td>
                          </tr>
                        )
                      )}

                      <tr>
                        <td></td>
                        <td>Overall rating for inputs (A,B,C,D,E,F)</td>
                        <td>
                          {evalDynamicDocument?.outputStandardFinalValue
                            ? evalDynamicDocument?.outputStandardFinalValue.toFixed(
                              2
                            )
                            : 0}{" "}
                          %
                        </td>
                        <td>
                          {evalDynamicDocument?.outputStandardLetter
                            ? evalDynamicDocument?.outputStandardLetter
                            : ""}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Accordion.Body>
              </Accordion>
              {
                // Competencies
              }
              <Accordion className="shadow roudned p-2 m-2">
                <Accordion.Header>Functional Competencies</Accordion.Header>
                <Accordion.Body>
                  <h6 className="h6p-2 m-2">
                    {evalDynamicDocument?.appraisalDocument.competenciesName}
                  </h6>
                  <p className="p-2 m-2">
                    {
                      evalDynamicDocument?.appraisalDocument
                        .competenciesDescription
                    }
                  </p>
                  {evalDynamicDocument?.appraisalDocument.competencies.map(
                    (c, i) => (
                      <div key={i} className="row shadow rounded p-4 m-2">
                        <h4 className="h6">{c.name}</h4>
                        <p>{c.explanation}</p>
                        <div className="row p-3 m-3">
                          <div className="col-lg-2 col-md-2 col-sm-1 col-xs-1">
                            <select
                              value={
                                //@ts-ignore
                                evalDynamicDocument?.competencies && evalDynamicDocument?.competencies[i]?.evaluation
                              }
                              onChange={({ target }) => {
                                const name = c.name;
                                const type = c.type;
                                const category = c.category;
                                //@ts-ignore
                                setEvalDynamicDocument({
                                  ...evalDynamicDocument,
                                  competencies: {
                                    ...evalDynamicDocument?.competencies,
                                    [i]: {
                                      evaluation: target.value,
                                      type,
                                      category,
                                      name,
                                    },
                                  },
                                });
                              }}
                              className="form-control"
                            >
                              <option className="form-control" value="F">
                                ---
                              </option>
                              <option className="form-control" value="A">
                                A – Expert level
                              </option>
                              <option className="form-control" value="B">
                                B – Advanced level
                              </option>
                              <option className="form-control" value="C">
                                C – Good Level
                              </option>
                              <option className="form-control" value="D">
                                D – Satisfactory level
                              </option>
                              <option className="form-control" value="E">
                                E – Deficiencies
                              </option>
                              <option className="form-control" value="F">
                                F – Significant deficiencies
                              </option>
                              <option className="form-control" value="X">
                                X – Impossible to evaluate
                              </option>
                            </select>
                          </div>
                          <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
                            {
                              //@ts-ignore
                              evalDynamicDocument.competencies && evalDynamicDocument?.competencies[i]
                                ?.evaluation && (
                                <>
                                  <textarea
                                    style={{ width: "100%" }}
                                    className="form-control"
                                    rows={3}
                                    value={
                                      //@ts-ignore
                                      evalDynamicDocument?.competencies && evalDynamicDocument?.competencies[i]?.evaluationInput
                                    }
                                    onChange={({ target }) => {
                                      let someObject = {
                                        ...evalDynamicDocument?.competencies,
                                        [i]: {
                                          evaluationInput: target.value,
                                          type: c.type,
                                          category: c.category,
                                          name: c.name,

                                          evaluation:
                                            //@ts-ignore
                                            evalDynamicDocument?.competencies[i]
                                              ?.evaluation,
                                        },
                                      };
                                      setEvalDynamicDocument({
                                        ...evalDynamicDocument,
                                        competencies: someObject,
                                      });
                                    }}
                                  />
                                </>
                              )
                            }
                          </div>
                        </div>
                      </div>
                    )
                  )}

                  <table className="table table-bordered p-2 m-2">
                    <tbody>
                      <tr>
                        <td colSpan={4}>
                          Overall rating for competencies (A,B,C,D,E,F,X)
                        </td>
                        <td>
                          {evalDynamicDocument.competenciesFinaPercentageNumber &&
                            evalDynamicDocument.competenciesFinaPercentageNumber.toFixed(
                              2
                            )}{" "}
                          %(
                          {(evalDynamicDocument.competenciesLetter === "A" &&
                            6) ||
                            (evalDynamicDocument.competenciesLetter === "B" &&
                              5) ||
                            (evalDynamicDocument.competenciesLetter === "C" &&
                              4) ||
                            (evalDynamicDocument.competenciesLetter === "D" &&
                              3) ||
                            (evalDynamicDocument.competenciesLetter === "E" &&
                              2) ||
                            (evalDynamicDocument.competenciesLetter === "F" &&
                              1) ||
                            (evalDynamicDocument.competenciesLetter === "X" &&
                              0)}
                          )
                        </td>
                        <td>
                          {evalDynamicDocument.competenciesLetter &&
                            evalDynamicDocument.competenciesLetter}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Accordion.Body>
              </Accordion>

              {
                // Comments
              }
              <Accordion className="shadow roudned p-2 m-2">
                <Accordion.Header>Comments</Accordion.Header>
                <Accordion.Body>
                  <h6 className="h6p-2 m-2">
                    {evalDynamicDocument?.appraisalDocument.commentsName}
                  </h6>
                  <p className="p-2 m-2">
                    {evalDynamicDocument?.appraisalDocument.commentsDescription}
                  </p>

                  {evalDynamicDocument?.appraisalDocument.comments.map(
                    (c, i) => (
                      <div key={i} className="row shadow rounded p-4 m-2">
                        <div className="col-lg-4 col-md-4 col-sm-1 col-xs-1">
                          {c.name}({c.explanation})
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-1 col-xs-1">
                          <textarea
                            rows={3}
                            className="form-control"
                            style={{ width: "100%" }}
                            value={
                              //@ts-ignore
                              evalDynamicDocument?.comments && evalDynamicDocument?.comments[i]?.evaluation
                            }
                            onChange={({ target }) => {
                              const name = c.name;
                              const type = c.type;
                              const category = c.category;
                              //@ts-ignore
                              setEvalDynamicDocument({
                                ...evalDynamicDocument,
                                comments: {
                                  ...evalDynamicDocument?.comments,
                                  [i]: {
                                    evaluation: target.value,
                                    type,
                                    category,
                                    name,
                                  },
                                },
                              });
                            }}
                          />
                        </div>
                      </div>
                    )
                  )}
                </Accordion.Body>
              </Accordion>
              {
                // Charts
              }
              <Accordion className="shadow roudned p-2 m-2">
                <Accordion.Header>Charts</Accordion.Header>
                <Accordion.Body>
                  {
                    evalDynamicDocument.competencies && <CompetenciesChart
                      EvalDynamicDocument={evalDynamicDocument}
                    />
                  }

                  {
                    evalDynamicDocument.inputsEnergizer && <EnergizerAssesmentChart
                      EvalDynamicDocument={evalDynamicDocument}
                    />
                  }
                  {
                    evalDynamicDocument.inputsStandard && <InputOutputChart EvalDynamicDocument={evalDynamicDocument} />
                  }

                </Accordion.Body>
              </Accordion>

              <div className="row rounded p-5 m-5 shadow">
                <h3 className="h5">Lets finish this up!</h3>
                <div className="row">
                  <div className="col-lg-5 col-md-5 col-sm-1 col-xs-1 shadow rouned p-2 m-3">
                    I,{" "}
                    <strong className="text-warning">
                      {data.name} {data.surname}
                    </strong>
                    , as a manager, hereby confirm by selecting the consent
                    button that the performance appraisal discussion was
                    completed!
                    <br />
                    <br />
                    <div
                      onClick={() => {
                        setEvalDynamicDocument({
                          ...evalDynamicDocument,
                          managerConsentButton:
                            !evalDynamicDocument.managerConsentButton,
                        });
                      }}
                      className={
                        evalDynamicDocument.managerConsentButton
                          ? "btn btn-success"
                          : "btn btn-danger"
                      }
                    >
                      {evalDynamicDocument.managerConsentButton
                        ? "Confirmed"
                        : "Not confirmed"}
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-5 col-sm-1 col-xs-1 shadow rouned p-2 m-3">
                    I,{" "}
                    <strong className="text-info">
                      {evalDynamicDocument.selectedRep.name}{" "}
                      {evalDynamicDocument.selectedRep.surname}
                    </strong>
                    , as an employee, hereby confirm by selecting the consent
                    button that the performance appraisal discussion was
                    completed!
                    <br />
                    <br />
                    <div
                      onClick={() => {
                        setEvalDynamicDocument({
                          ...evalDynamicDocument,
                          employeeConsentButton:
                            !evalDynamicDocument.employeeConsentButton,
                        });
                      }}
                      className={
                        evalDynamicDocument.employeeConsentButton
                          ? "btn btn-success"
                          : "btn btn-danger"
                      }
                    >
                      {evalDynamicDocument.employeeConsentButton
                        ? "Confirmed"
                        : "Not confirmed"}
                    </div>
                  </div>
                </div>

                {evalDynamicDocument.employeeConsentButton &&
                  evalDynamicDocument.managerConsentButton && (
                    <div className="row justify-content-center align-items-center p-5">
                      <h4 className="h5 text-center">Confirm today's date</h4>
                      <input
                        readOnly={true}
                        value={new Date().toISOString().substring(0, 10)}


                        type="date"
                        className="form-control"
                        onChange={(e: any) => {
                          if (e.target.value.length > 0) {
                            setEvalDynamicDocument({
                              ...evalDynamicDocument,
                              dateConfirmation: new Date(e.target.value),
                            });
                          } else {
                            setEvalDynamicDocument({
                              ...evalDynamicDocument,
                              dateConfirmation: new Date(),
                            });
                            dispatch(setError("Invalid date chosen"));
                          }
                        }}
                      />
                      {evalDynamicDocument.dateConfirmation &&
                        evalDynamicDocument.managerConsentButton &&
                        evalDynamicDocument.employeeConsentButton && (
                          <div
                            onClick={saveEvaluationForGivenColleague}
                            className="btn btn-primary m-5"
                          >
                            Save evaluation!
                          </div>
                        )}
                    </div>
                  )}
              </div>
            </Accordion.Body>
          </Accordion>
        )}
    </AdminContainer>
  );
};

export default AppraisalColleagueEvaluation;
