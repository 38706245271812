import AdminContainer from "../../admin-components/AdminContainer";
import { useState } from "react";
import { UserInterface } from "../../interface/UserInterface";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setError } from "../../store/reducers/errorReducer";
import { Roles } from "../../interface/Enums/Roles.enum";
import {  H1 } from "../../components/StyledComponents";
import { fetchAPI } from "../../utils/FetchApi";
import { FetchMethods } from "../../interface/Enums/methods.enum";
import CountryFilter from "../../components/CountryFilter";
import RegisterUser from "../../components/RegisterUser";
// styles
import Dropdown from "react-bootstrap/Dropdown";
import {
  BsThreeDotsVertical,
  BsFillPersonCheckFill,
  BsFillPersonXFill,
  BsTrashFill,
  BsEyeFill,
} from "react-icons/bs";
import Badge from "react-bootstrap/Badge";
import Accordion from "react-bootstrap/Accordion";
import { IDepartment, IJobname } from "../../interface";
import { useNavigate } from "react-router-dom";
import { BtnCreate } from "../../components/StyledComponents";

interface CountryInterface {
  name: string;
  createdAt: Date;
  updatedAt: Date;
  _id: string;
}
const AdminPanel = () => {
  const dispatch = useDispatch();
  const navigate: (routename: string) => void = useNavigate();
  const currentUserId: any = useSelector((state: any) => state.auth.user._id);

  const [listenForChange, setListenForChange] = useState<boolean>(false);
  const [users, setUsers] = useState<UserInterface[]>([]);
  const [countries, setCountries] = useState<CountryInterface[]>([]);
  const [departments, setDepartments] = useState<IDepartment[]>([]);
  const [jobnames, setJobnames] = useState<IJobname[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [showCreateEmployee, setShowCreateEmployee] = useState<boolean>(false);
  const [pass, setPass] = useState<string>("");
  const [pwdMessage, setPwdMessage] = useState<string>("");

  const deleteUser = async (index: number) => {
    const conf = window.confirm(
      `Do you really want to delete ${users[index].email}`
    );
    if (conf) {
      try {
        const data: any = await fetchAPI(
          `/users/delete/${users[index]._id}`,
          FetchMethods.DELETE
        );

        //throw an error if data has property error
        if (data.hasOwnProperty("error")) {
          throw new Error(data.message);
        }

        setListenForChange(!listenForChange);
      } catch (error: any) {
        dispatch(setError(error.toString()));
      }
    }
  };

  const updateUserAnything = async (
    index: number,
    partial: Partial<UserInterface>
  ) => {
    try {
      //dangerous line
      users[index].master = users[index].master._id;

      const dataForUpdate = Object.assign(users[index], partial);

      const data: any = await fetchAPI(
        `/users/update/${users[index]._id}`,
        FetchMethods.PATCH,
        dataForUpdate
      );

      if (data.hasOwnProperty("error")) {
        //@ts-ignore
        throw new Error(data.message.toString());
      }
      setListenForChange(!listenForChange);
    } catch (error: any) {
      dispatch(setError(error.toString()));
    }
  };

  const pickCountry = (country: string): void => {
    setSelectedCountry(country);
  };

  const finishCreatingEmployee = () => {
    setListenForChange(!listenForChange);
    setShowCreateEmployee(false);
  };

  useEffect(() => {
    if (selectedCountry.length > 0) {
      (async () => {
        //get users
        try {
          const data: UserInterface[] = await fetchAPI(
            `/users/all/${selectedCountry}`,
            FetchMethods.GET
          );
          if (data.hasOwnProperty("error")) {
            setUsers([]);
          } else {
            (async () => {
              //get users
              try {
                const data: UserInterface[] = await fetchAPI(
                  `/users/all/${selectedCountry}`,
                  FetchMethods.GET
                );

                if (data.hasOwnProperty("error")) {
                  setUsers([]);
                } else {
                  //sort array of users by name

                  setUsers(data.sort((a, b) => (a.name > b.name ? 1 : -1)));
                }
              } catch (error: any) {
                if (error) {
                  dispatch(setError(error.message));
                }
              }

              //get all countries
              try {
                const countries: CountryInterface[] = await fetchAPI(
                  `/admincmds/get-all-countries/`,
                  FetchMethods.GET
                );
                countries.sort((a, b) => (a.name > b.name ? 1 : -1));
                setCountries(countries);
              } catch (error: any) {
                dispatch(setError(error.message.toString()));
              }
            })();
            setUsers(data);
          }
        } catch (error: any) {
          if (error) {
            dispatch(setError(error.message));
          }
        }

        //get all countries
        try {
          const countries: CountryInterface[] = await fetchAPI(
            `/admincmds/get-all-countries/`,
            FetchMethods.GET
          );
          countries.sort((a, b) => (a.name > b.name ? 1 : -1));
          setCountries(countries);
        } catch (error: any) {
          dispatch(setError(error.message.toString()));
        }
      })();
    }
  }, [listenForChange, selectedCountry, dispatch]);

  //Load departments

  useEffect(() => {
    if (selectedCountry.length > 0) {
      (async () => {
        try {
          const data: IDepartment[] = await fetchAPI(
            `/admincmds/get-all-departments/${selectedCountry}`,
            FetchMethods.GET
          );
          if (data.length > 0) {
            setDepartments(data);
          }
        } catch (error: any) {
          dispatch(setError(error.message.toString()));
        }

        try {
          const data: IJobname[] = await fetchAPI(
            `/admincmds/get-all-jobnames/${selectedCountry}`,
            FetchMethods.GET
          );
          if (data.length > 0) {
            setJobnames(data);
          }
        } catch (error: any) {
          dispatch(setError(error.message.toString()));
        }
      })();
    }
  }, [selectedCountry, dispatch]);

  const updatePass = async (object: { password: string; id: string }) => {
    if (!pass.match(/((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/)) {
      dispatch(
        setError(
          "Password is too weak - it has to contain at least one Capital Letter and one number."
        )
      );
    } else {
      try {
        const data: Response = await fetchAPI(
          "/admincmds/update-password",
          FetchMethods.POST,
          object
        );
        if (data.hasOwnProperty("error")) {
          //@ts-ignore
          throw new Error(data.message.toString());
        }

        setPass("");
        setPwdMessage("Password has been successfully updated");

        setTimeout(() => {
          setPwdMessage("");
        }, 2000);
      } catch (error: any) {
        dispatch(setError(error.toString()));
        setPwdMessage(error.toString());
      }
    }
  };
  return (
    <AdminContainer>
      <H1>User management</H1>
      <div className="row justify-content-between align-items-center">
        <div className="col-lg-8 m-2">
          <CountryFilter returnedCountry={pickCountry} />
        </div>
        <div className="col-lg-2 m-2 p-1">
          <BtnCreate onClick={() => {
                setShowCreateEmployee(!showCreateEmployee);
              }}>
              Create new employee
          </BtnCreate>
        </div>
      </div>
      {showCreateEmployee && (
        <RegisterUser
          usersAsManagers={users}
          countrylist={countries}
          finishit={finishCreatingEmployee}
        />
      )}
      <hr className="hr" />
      <h2 className="h2">Manage users in the {selectedCountry}</h2>
      <Accordion className="border rounded my-1">
        <Accordion.Header>Standard View</Accordion.Header>
        <Accordion.Body>
        <div className="row">
        {users.length > 0 &&
          users.map((userRegular: UserInterface, index) => (
            <div
              key={index}
              className={`col-sm-12 shadow rounded p-2 m-1 ${
                userRegular.isUserApproved ? "bg-success" : "bg-warning"
              }`}
            >
              <div className="row justify-content-between">
                <div className="col-sm-2 align-self-start">
                  <Dropdown>
                    <Dropdown.Toggle size="sm" variant="info">
                      <BsThreeDotsVertical />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                      className="flex flex-row gap-2 items-center"
                        onClick={() => {
                          //updateUserAnything(index, { isUserApproved: true });
                          navigate(`/user/detail/${userRegular._id}`);
                        }}
                      >
                        <BsEyeFill /> View appraisal evaluation
                      </Dropdown.Item>
                      <Dropdown.Item
                           className="flex flex-row gap-2 items-center"
                        onClick={() => {
                          //updateUserAnything(index, { isUserApproved: true });
                          navigate(
                            `/user/anual-evaluation-detail/${userRegular._id}`
                          );
                        }}
                      >
                        <BsEyeFill /> View annual evaluation
                      </Dropdown.Item>
                      {
                        //User can not disapprove or approve himself
                      }
                      {userRegular._id !== currentUserId && (
                        <>
                          <Dropdown.Item
                               className="flex flex-row gap-2 items-center"
                            onClick={() => {
                              updateUserAnything(index, {
                                isUserApproved: true,
                              });
                            }}
                          >
                            <BsFillPersonCheckFill /> Approve
                          </Dropdown.Item>

                          <Dropdown.Item
                               className="flex flex-row gap-2 items-center"
                            onClick={() => {
                              updateUserAnything(index, {
                                isUserApproved: false,
                              });
                            }}
                          >
                            <BsFillPersonXFill /> Disapprove
                          </Dropdown.Item>
                        </>
                      )}

                      <Dropdown.Item
                           className="flex flex-row gap-2 items-center"
                        onClick={() => {
                          deleteUser(index);
                        }}
                      >
                        <BsTrashFill /> Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="col-lg-10">
                  <Accordion>
                    <Accordion.Item eventKey={index.toString()}>
                      <Accordion.Header>
                          <div className="row flex-row" style={{width:"100%"}}>
                            <div
                              className={`${
                                userRegular.authLevel === "omni-admin" &&
                                userRegular.email === "jaroslav.huss@gmail.com"
                                  ? "text-danger font-weight-bold"
                                  : userRegular.authLevel === "omni-admin"
                                  ? "text-primary"
                                  : userRegular.authLevel === "area-manager"
                                  ? "text-warning"
                                  : "text-secondary"
                              } col-3`}
                            >
                              <strong>NAME:</strong> <br />
                              {userRegular && userRegular.name}{" "}
                              {userRegular && userRegular.surname}{" "}
                            </div>
                            <div className={`${
                                userRegular.authLevel === "omni-admin" &&
                                userRegular.email === "jaroslav.huss@gmail.com"
                                  ? "text-danger font-weight-bold"
                                  : userRegular.authLevel === "omni-admin"
                                  ? "text-primary"
                                  : userRegular.authLevel === "area-manager"
                                  ? "text-warning"
                                  : "text-secondary"
                              } col-3`}>
                              <strong>MANAGER:</strong>
                              <br />
                              {userRegular && userRegular.master.name}{" "}
                              {userRegular && userRegular.master.surname}
                            </div>
                            <div className={`${
                                userRegular.authLevel === "omni-admin" &&
                                userRegular.email === "jaroslav.huss@gmail.com"
                                  ? "text-danger font-weight-bold"
                                  : userRegular.authLevel === "omni-admin"
                                  ? "text-primary"
                                  : userRegular.authLevel === "area-manager"
                                  ? "text-warning"
                                  : "text-secondary"
                              } col-3`}>
                              <strong>AUTH-LEVEL:</strong>
                              <br />
                              {userRegular && userRegular.authLevel}
                            </div>
                            <div className={`${
                                userRegular.authLevel === "omni-admin" &&
                                userRegular.email === "jaroslav.huss@gmail.com"
                                  ? "text-danger font-weight-bold"
                                  : userRegular.authLevel === "omni-admin"
                                  ? "text-primary"
                                  : userRegular.authLevel === "area-manager"
                                  ? "text-warning"
                                  : "text-secondary"
                              } col-3`}>
                              <strong>DEPARTMENT:</strong>
                              <br />
                              {userRegular && userRegular.department?.name}
                            </div>
                          </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <Accordion>
                          <Accordion.Header className="border rounded">
                            <span>
                              <strong> Basic user info & update</strong> -{" "}
                              <small>
                                {new Date(
                                  userRegular.updatedAt
                                ).toLocaleString()}
                              </small>
                            </span>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="row m-1 p-1 align-items-center justify-items-center">
                              <div className="col-lg-4">
                                <Badge>Email</Badge>
                              </div>
                              <div className="col-lg-8">
                                <div className="row">
                                  <div className="col-8">
                                    <input
                                      className="form-control"
                                      type="email"
                                      value={userRegular.email}
                                      onChange={({ target }) => {
                                        const newArrayOfUsers = users.map(
                                          (user, i) => {
                                            if (i === index) {
                                              return {
                                                ...user,
                                                email: target.value,
                                              };
                                            }
                                            return user;
                                          }
                                        );
                                        setUsers(newArrayOfUsers);
                                      }}
                                    />
                                  </div>

                                  <div className="col-3">
                                    <div className="row">
                                      <div
                                        className="btn btn-danger"
                                        onClick={() => {
                                          updateUserAnything(index, {
                                            email: users[index].email,
                                          });
                                        }}
                                      >
                                        update
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row m-1 p-1 align-items-center justify-items-center">
                              <div className="col-lg-4">
                                <Badge>Password</Badge>
                              </div>
                              <div className="col-lg-8">
                                <div className="row">
                                  <div className="col-8">
                                    <input
                                      className="form-control"
                                      type="password"
                                      value={pass}
                                      onChange={({ target }) => {
                                        setPass(target.value);
                                      }}
                                    />
                                  </div>

                                  <div className="col-3">
                                    <div className="row">
                                      <div
                                        className="btn btn-danger"
                                        onClick={() => {
                                          updatePass({
                                            password: pass,
                                            id: users[index]._id,
                                          });
                                        }}
                                      >
                                        update
                                      </div>
                                      <span style={{ fontSize: 10 }}>
                                        {pwdMessage}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row m-1 p-1 border-bottom">
                              <div className="col-lg-4">
                                <Badge>Approved</Badge>
                              </div>
                              <div className="col-lg-8">
                                {userRegular.isUserApproved
                                  ? "User approved"
                                  : "User is not approved - can not access the system!"}
                              </div>
                            </div>
                            <div className="row m-1 p-1 border-bottom">
                              <div className="col-lg-4">
                                <Badge>Country</Badge>
                              </div>
                              <div className="col-lg-8">
                                <select
                                  className="form-control"
                                  onChange={(e) => {
                                    updateUserAnything(index, {
                                      country: e.target.value,
                                    });
                                  }}
                                >
                                  <option
                                    className="form-control"
                                    defaultValue={userRegular.country}
                                    value={userRegular.country}
                                  >
                                    {userRegular.country}
                                  </option>
                                  {countries.map(
                                    (c: CountryInterface, i: number) => (
                                      <option key={i} value={c.name}>
                                        {c.name}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                            </div>

                            <div className="row m-1 p-1 border-bottom">
                              <div className="col-lg-4">
                                <Badge>Team/Department</Badge>
                              </div>
                              <div className="col-lg-8">
                                <select
                                  className="form-control"
                                  onChange={(e) => {
                                    updateUserAnything(index, {
                                      department:
                                        departments[parseInt(e.target.value)],
                                    });
                                  }}
                                >
                                  {userRegular.department ? (
                                    <option
                                      className="form-control"
                                      defaultValue={
                                        userRegular.department?.name
                                      }
                                      value={userRegular.department?.name}
                                    >
                                      {userRegular.department?.name}
                                    </option>
                                  ) : (
                                    <option
                                      className="form-control"
                                      defaultValue="---"
                                      value="---"
                                    >
                                      ---
                                    </option>
                                  )}
                                  {departments.map(
                                    (c: IDepartment, i: number) => (
                                      <option key={i} value={i}>
                                        {c.name}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                            </div>
                            <div className="row m-1 p-1 border-bottom">
                              <div className="col-lg-4">
                                <Badge>Official Position / Jobname</Badge>
                              </div>
                              <div className="col-lg-8">
                                <select
                                  className="form-control"
                                  onChange={(e) => {
                                    updateUserAnything(index, {
                                      jobName:
                                        jobnames[parseInt(e.target.value)],
                                    });
                                  }}
                                >
                                  {userRegular.jobName ? (
                                    <option
                                      className="form-control"
                                      defaultValue={userRegular.jobName?.name}
                                      value={userRegular.jobName?.name}
                                    >
                                      {userRegular.jobName?.name}
                                    </option>
                                  ) : (
                                    <option
                                      className="form-control"
                                      defaultValue="---"
                                      value="---"
                                    >
                                      ---
                                    </option>
                                  )}
                                  {jobnames.map((c: IJobname, i: number) => (
                                    <option key={i} value={i}>
                                      {c.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>

                            <div className="row m-1 border-bottom">
                              <div className="col-lg-4">
                                <Badge>Current position held since</Badge>
                              </div>
                              <div className="col-lg-8">
                                <input
                                  value={
                                    userRegular.currentPositionHeldSince
                                      ? new Date(
                                          userRegular.currentPositionHeldSince
                                        )
                                          .toISOString()
                                          .substring(0, 10)
                                      : new Date()
                                          .toISOString()
                                          .substring(0, 10)
                                  }
                                  type="date"
                                  className="form-control"
                                  onChange={(e: any) => {
                                    if (e.target.value.length > 0) {
                                      updateUserAnything(index, {
                                        currentPositionHeldSince: new Date(
                                          e.target.value
                                        ),
                                      });
                                    } else {
                                      dispatch(setError("Invalid date chosen"));
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <div className="row m-1 border-bottom">
                              <div className="col-lg-4">
                                <Badge>Start date of Employment</Badge>
                              </div>
                              <div className="col-lg-8">
                                <input
                                  value={
                                    userRegular.startDateOfEmployment
                                      ? new Date(
                                          userRegular.startDateOfEmployment
                                        )
                                          .toISOString()
                                          .substring(0, 10)
                                      : new Date()
                                          .toISOString()
                                          .substring(0, 10)
                                  }
                                  type="date"
                                  className="form-control"
                                  onChange={(e: any) => {
                                    if (e.target.value.length > 0) {
                                      updateUserAnything(index, {
                                        startDateOfEmployment: new Date(
                                          e.target.value
                                        ),
                                      });
                                    } else {
                                      dispatch(setError("Invalid date chosen"));
                                    }
                                  }}
                                />
                              </div>
                            </div>

                            <div className="row m-1 p-1 border-bottom bg-warning">
                              <div className="col-sm-4">
                                <Badge className="bg-danger">System Role</Badge>
                              </div>
                              <div className="col-sm-8">
                                <select
                                  className="form-control"
                                  onChange={(e) => {
                                    updateUserAnything(index, {
                                      authLevel: e.target.value,
                                    });
                                  }}
                                >
                                  <option
                                    defaultValue={userRegular.authLevel}
                                    value={userRegular.authLevel}
                                  >
                                    {userRegular.authLevel}
                                  </option>
                                  {Roles.AreaManager !==
                                    userRegular.authLevel && (
                                    <option value={Roles.AreaManager}>
                                      {Roles.AreaManager}
                                    </option>
                                  )}
                                  {Roles.MedicalRepresentative !==
                                    userRegular.authLevel && (
                                    <option value={Roles.MedicalRepresentative}>
                                      {Roles.MedicalRepresentative}
                                    </option>
                                  )}
                                  
                                </select>
                              </div>
                            </div>

                            <hr className="hr" />
                            <div className="row m-1 p-1">
                              <h4 className="h4">Assign a new manager</h4>
                              <select
                                className="form-control"
                                onChange={({ target }) => {
                                  updateUserAnything(index, {
                                    master: target.value,
                                  });
                                }}
                              >
                                {userRegular.master ? (
                                  <option
                                    defaultValue={userRegular.master._id}
                                    value={userRegular.master._id}
                                  >
                                    {userRegular.master.name}{" "}
                                    {userRegular.master.surname}
                                  </option>
                                ) : (
                                  <option defaultValue={-1} value={-1}>
                                    {"---"}
                                  </option>
                                )}
                                {users.map((u: UserInterface, i: number) => {
                                  return (
                                    <option key={i} value={u._id}>
                                      {u.name} {u.surname}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </Accordion.Body>
                        </Accordion>

                        <Accordion>
                          <Accordion.Header className="rounded border">
                            GDPR
                          </Accordion.Header>
                          <Accordion.Body>
                            {userRegular.gdprConsent && "User consented on:"}{" "}
                            {userRegular.gdprConsentDate &&
                              new Date(
                                userRegular.gdprConsentDate
                              ).toLocaleDateString()}
                            {!userRegular.gdprConsent && (
                              <p>User did not consent yet...</p>
                            )}
                          </Accordion.Body>
                        </Accordion>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>
          ))}
      </div>
        </Accordion.Body>
      </Accordion>
     <Accordion className="border rounded my-1">
      <Accordion.Header>
        Hierarchial view
        </Accordion.Header>
        <Accordion.Body>
          {/*This component works the same like the Standard view component, but it does not list one user after another one. First, it displays users with auth-level === "omni-admin". For each omni-admin there is a loop that displays Omni admins's "area-manager". And finally, for each area managers it displays all his/her medical-representative*/}
                              <p>
                                Hierarchial view helps admins to better check if the setup is done properly. It displays all users in a tree-like structure. First, it displays all omni-admins. For each omni-admin there is a loop that displays Omni admins's "area-manager". And finally, for each area managers it displays all his/her medical-representative
                              </p>
                              <p className="text-warning font-weight-bold">
                                Please bear in mind that all UPDATES or modifications must be done in the Standard view.
                              </p>
          <div className="row">
            {
              //This is the first loop that displays all omni-admins
              users

                .filter((u: UserInterface) => u.authLevel === Roles.OmniAdmin)
                .map((u: UserInterface, i: number) => (
                  <div key={i} className="col-lg-12">
                    <Accordion className="border rounded my-1">
                      <Accordion.Header>
                        {u.name} {u.surname}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="row">
                          {
                            //This is the second loop that displays all area-managers for each omni-admin
                            users
                              .filter(
                                (u2: UserInterface) =>

                                  u2.authLevel === Roles.AreaManager &&
                                  u2.master &&
                                  u2.master._id === u._id
                              )
                              .map((u2: UserInterface, i2: number) => (
                                <div className="row">
                                <div className="col-lg-1 align-self-start">
                  <Dropdown>
                    <Dropdown.Toggle size="sm" variant="info">
                      <BsThreeDotsVertical />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          //updateUserAnything(index, { isUserApproved: true });
                          navigate(`/user/detail/${u2._id}`);
                        }}
                      >
                        <BsEyeFill /> View appraisal evaluation
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          //updateUserAnything(index, { isUserApproved: true });
                          navigate(
                            `/user/anual-evaluation-detail/${u2._id}`
                          );
                        }}
                      >
                        <BsEyeFill /> View annual evaluation
                      </Dropdown.Item>
                    
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div key={i2} className="col-lg-11">
                                  <Accordion className="border rounded my-1">
                                    <Accordion.Header>
                                      {u2.name} {u2.surname}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <div className="row">
                                        {
                                          //This is the third loop that displays all medical-representatives for each area-manager
                                          users
                                            .filter(
                                              (u3: UserInterface) =>
                                                u3.authLevel ===
                                                  Roles.MedicalRepresentative &&
                                                u3.master &&
                                                u3.master._id === u2._id
                                            )
                                            .map((u3: UserInterface, i3: number) => (
                                              <div key={i3} className="row">
                                                <div className="col-lg-1 align-self-start">
                  <Dropdown>
                    <Dropdown.Toggle size="sm" variant="info">
                      <BsThreeDotsVertical />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          //updateUserAnything(index, { isUserApproved: true });
                          navigate(`/user/detail/${u3._id}`);
                        }}
                      >
                        <BsEyeFill /> View appraisal evaluation
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          //updateUserAnything(index, { isUserApproved: true });
                          navigate(
                            `/user/anual-evaluation-detail/${u3._id}`
                          );
                        }}
                      >
                        <BsEyeFill /> View annual evaluation
                      </Dropdown.Item>
                    
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="col-lg-11 align-self-start"><Accordion className="border rounded my-1">
                                                  <Accordion.Header>
                                                    {u3.name} {u3.surname}
                                                  </Accordion.Header>
                                                  <Accordion.Body>
                                                    <div className="row">
                                                  
                                                  <div className="row">
                                                    <div className="col">
                                                      <strong>EMAIL</strong><br />
                                                      {u3&&u3.email}
                                                    </div>
                                                    <div className="col">
                                                      <strong>Job Name</strong><br />
                                                      {u3&&u3.jobName?.name}
                                                    </div>
                                                    <div className="col">
                                                      <strong>Start date of employment</strong><br />
                                                      {u3.startDateOfEmployment && new Date(u3.startDateOfEmployment).toLocaleDateString()}
                                                    </div>
                                                  </div>
                                                      </div>
                                                  </Accordion.Body>
                                                </Accordion></div>
                                                
                                              </div>
                                            ))
}
                                      </div>
                                    </Accordion.Body>
                                  </Accordion>
                                </div>
                                </div>
                                
                              ))
}
                        </div>
                      </Accordion.Body>
                    </Accordion>
                  </div>
                ))

                                            
            }
          </div>
        </Accordion.Body>
     </Accordion>
    </AdminContainer>
  );
};

export default AdminPanel;
