import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { FetchMethods } from '../../interface/Enums/methods.enum';
import { IEvaluationDynamicDocument } from '../../interface';
import { setError } from '../../store/reducers/errorReducer';
import { fetchAPI } from '../../utils/FetchApi';
import EvaluationDocumentMasterComponents from "../AdminRoutes/AppraisalManagement/ColleageEvaluationComponents/EvaluationDocumentMasterComponents";
import ManyToInputOutput from "../../components/Charts/ManyToInputOutput.chart"
import ManyToCompetencies from "../../components/Charts/ManyToCompetencies.chart"
import ManyToEnergizer from "../../components/Charts/ManyToEnergizerAssesment.chart"
import { Accordion } from 'react-bootstrap';
import AnnualEvaluationMasterDisplayComponent from '../AdminRoutes/AnnualManagement/CreateAnnualEvaluationComponents/ComponentsForEvaluation/AnnualEvaluationMasterDisplayComponent';
import AccordionBody from 'react-bootstrap/esm/AccordionBody';

const MedicalRepDashboard = ({medrepid}:{medrepid:string}) => {
  const [evalDocs, setEvalDocs] = useState<IEvaluationDynamicDocument[]>([]);
  const [annualDocs, setAnnualDocs] = useState<any[]>([]);
  const dispatch = useDispatch()
  useEffect(() => {
    (async()=>{
      try {
          const data:IEvaluationDynamicDocument[] = await fetchAPI(`/admincmds/get-evaluation-document-by-specific-user/${medrepid}`,FetchMethods.GET );
          if(data.hasOwnProperty("error")){
              //@ts-ignore
          throw new Error(data.message.toString());
          }
    
          if(data.length>0){
          setEvalDocs(data);
         }

      } catch (error:any) {
         dispatch(setError(error.toString()))
      }   
  })();

  (async()=>{
    try {
      const data:any[] = await fetchAPI(`/evaluationcmds/annual-evaluations/user/${medrepid}`,FetchMethods.GET );
      if(data.hasOwnProperty("error")){
          //@ts-ignore
      throw new Error(data.message.toString());
      }
      if(data.length>0){
      setAnnualDocs(data);
     }

  } catch (error:any) {
     dispatch(setError(error.toString()))
  }  
  })()


return ()=>{
setEvalDocs([])
}
  }, [medrepid, dispatch])
  
  return (
    <>
    <Accordion className='border shadow p-2 rounded'>
      <Accordion.Header>
        <h5 className="h5">History - Appraisal Documents</h5>
      </Accordion.Header>
      <Accordion.Body>

      <div className='row'>
        {evalDocs.length>0&&evalDocs.map((e:IEvaluationDynamicDocument, i:number)=>(
           
            <EvaluationDocumentMasterComponents width={"100%"} position='relative' key={i} evaldoc={e}/>
        ))}
    </div>
    {
        evalDocs.length>0&&
        <Accordion className="row p-2 m-2 text-center mx-auto border rounded" style={{maxWidth:700}}>
        <Accordion.Header>
          Summary Charts
        </Accordion.Header>
        <AccordionBody>
        <div className="row shadow rounded border p-1 m-1">
            <h5 className="h5">Inputs & Outputs</h5>
            <ManyToInputOutput EvalDynamicDocument={evalDocs}/>
            </div>
            <div className="row shadow rounded border p-1 m-1">
            <h5 className="h5">Competencies</h5>
            <ManyToCompetencies EvalDynamicDocument={evalDocs}/>
            </div>
            <div className="row shadow rounded border p-1 m-1">
            <h5 className="h5">Energizer</h5>
            <ManyToEnergizer EvalDynamicDocument={evalDocs}/>
            </div>
            </AccordionBody>
    </Accordion>
    }
      </Accordion.Body>
    </Accordion>
    <Accordion className='bordered shadow p-2 rounded'>
      <Accordion.Header>
        <h5 className="h5">History - Annual Documents</h5>
        </Accordion.Header>
      <Accordion.Body>
      <div className='row'>
        {annualDocs.length>0&&annualDocs.map((e:any, i:number)=>(
           <AnnualEvaluationMasterDisplayComponent width={"100%"} position='relative' key={i} evaldoc={e}/>      
        ))}
    </div>
        </Accordion.Body>
    </Accordion>
   
    </>
  )
}

export default MedicalRepDashboard