
import { useEffect, useState } from 'react'
import { H1, H3, Section, InfoBox, BtnCreate, Grid12, H2 } from '../../components/StyledComponents'
import { TBiQuestion } from '../../interface/Bidirectional/type'
import AppraisalModal from "../../components/AppraisalModal"
import OpenQuestion from './components/OpenQuestion'
import RangeQuestion from './components/RangeQuestion'
import { EmptyOpenQuestion, EmptyRangeQuestion } from '../../interface/Bidirectional/empty'
import { IOpenQuestion, IRangeQuestion } from '../../interface/Bidirectional/interface'
import QuestionsList from './QuestionsList'
const QuestionsCreate = () => {
    const [questionType, setQuestionType] = useState<TBiQuestion | null>()
    const [showModal, setShowModal] = useState<boolean>(false); //create modal
    const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false); //update modal
    const [questionToBeUpdated, setQuestionToBeUpdated] = useState<IOpenQuestion | IRangeQuestion | null>(null);

    const [refetchQuestionList, setRefetchQuestionList] = useState<boolean>(false);
    /**
     * Empty Question Logic
     */
    const [emptyQuestionStateObject, setEmptyQuestionStateObject] = useState<IOpenQuestion>(EmptyOpenQuestion);
    /**
 * Empty Range Logic
 */
    const [emptyRangeQuestionStateobject, setEmptyRangeQuestionStateobject] = useState<IRangeQuestion>(EmptyRangeQuestion);

    useEffect(() => {
        if (showModal) {
            setEmptyQuestionStateObject(EmptyOpenQuestion);
            setEmptyRangeQuestionStateobject(EmptyRangeQuestion);
        }
    }, [showModal])
    return (
        <Section>
            <H1>Questions Management</H1>
            <Grid12>
                <InfoBox className='col-span-12 md:col-span-10'>
                    <p>
                        Here you can create questions for the bidirectional appraisal.
                    </p>
                </InfoBox>
                <BtnCreate onClick={() => { setShowModal(!showModal) }} className='col-span-12 md:col-span-2'>Create Question</BtnCreate>
            </Grid12>
            <H2 className='my-4'>List of all questions</H2>
            <Grid12>
                <QuestionsList setUpdate={(question: IOpenQuestion | IRangeQuestion) => {
                    setQuestionToBeUpdated(question);
                    setShowUpdateModal(!showUpdateModal);
                }} refetch={refetchQuestionList} setRefetch={() => { setRefetchQuestionList(!refetchQuestionList) }} />
            </Grid12>
            {/* MODAL FOR CREATE A QUESTION */}
            <AppraisalModal title='Create a question' show={showModal} handleClose={() => {
                setQuestionType(null)
                setShowModal(!showModal)
            }}>
                <H3>Select a question type</H3>
                <select className='form-select' onChange={(e) => {
                    setQuestionType(e.target.value as TBiQuestion)
                }}>
                    <option value={""}>---</option>
                    <option value="range">Range</option>
                    <option value="open-question">Open Question</option>
                </select>
              
                {/* OPEN TYPE */}
                {questionType === "open-question" && <OpenQuestion refetchQuestions={() => {
                    setRefetchQuestionList(!refetchQuestionList)
                    setShowModal(!showModal)
                }
                } question={emptyQuestionStateObject} setter={(q: IOpenQuestion) => {
                    setEmptyQuestionStateObject(q);
                }} />}
                {/* RANGE TYPE */}
                {
                    questionType === "range" && <RangeQuestion refetchQuestions={() => {
                        setRefetchQuestionList(!refetchQuestionList)
                        setShowModal(!showModal)
                    }
                    } question={emptyRangeQuestionStateobject} setter={(q: IRangeQuestion) => {
                        setEmptyRangeQuestionStateobject(q);
                    }
                    } />
                }
            </AppraisalModal>
            {
                // MODAL FOR UPDATE A QUESTION
                questionToBeUpdated && <AppraisalModal title='Update a question' show={showUpdateModal} handleClose={() => {
                    setQuestionType(null)

                    setShowUpdateModal(!showUpdateModal)
                }}>
                    {/* OPEN QUESTION UPDATE*/}
                    {questionToBeUpdated.type === "open-question" &&
                        <OpenQuestion refetchQuestions={() => {
                            setRefetchQuestionList(!refetchQuestionList)
                            setShowUpdateModal(!showUpdateModal)
                        }
                        } question={questionToBeUpdated as IOpenQuestion} setter={(q: IOpenQuestion) => {
                            setQuestionToBeUpdated(q);
                        }} />
                    }
                    {/* RANGE QUESTION UPDATE*/}

                    {
                        questionToBeUpdated.type === "range" &&
                        <RangeQuestion refetchQuestions={() => {
                            setRefetchQuestionList(!refetchQuestionList)
                            setShowUpdateModal(!showUpdateModal)
                        }
                        } question={questionToBeUpdated as IRangeQuestion} setter={(q: IRangeQuestion) => {
                            setQuestionToBeUpdated(q);
                        }
                        } />
                    }
                </AppraisalModal>
            }

 {
                // MODAL FOR UPDATE A QUESTION
                questionToBeUpdated && <AppraisalModal title='Update a question' show={showUpdateModal} handleClose={() => {
                    setQuestionType(null)

                    setShowUpdateModal(!showUpdateModal)
                }}>
                    {/* OPEN QUESTION UPDATE*/}
                    {questionToBeUpdated.type === "open-question" &&
                        <OpenQuestion refetchQuestions={() => {
                            setRefetchQuestionList(!refetchQuestionList)
                            setShowUpdateModal(!showUpdateModal)
                        }
                        } question={questionToBeUpdated as IOpenQuestion} setter={(q: IOpenQuestion) => {
                            setQuestionToBeUpdated(q);
                        }} />
                    }
                    {/* RANGE QUESTION UPDATE*/}

                    {
                        questionToBeUpdated.type === "range" &&
                        <RangeQuestion refetchQuestions={() => {
                            setRefetchQuestionList(!refetchQuestionList)
                            setShowUpdateModal(!showUpdateModal)
                        }
                        } question={questionToBeUpdated as IRangeQuestion} setter={(q: IRangeQuestion) => {
                            setQuestionToBeUpdated(q);
                        }
                        } />
                    }
                </AppraisalModal>
            }
        </Section>
    )
}

export default QuestionsCreate