import React, { useEffect, useState} from 'react';
import AdminContainer from '../../admin-components/AdminContainer';
import CountryFilter from '../../components/CountryFilter';
import { IDepartment, IError, UserInterface} from '../../interface';
import TeamAssessmentDetailComponent from '../../components/TeamAssesmentDetailComponent';


import { useSelector } from 'react-redux';
import { fetchAPI } from '../../utils/FetchApi';
import { FetchMethods } from '../../interface/Enums/methods.enum';
import { H2 } from '../../components/StyledComponents';


interface GlobalScope {
    
    auth: {
        user: {
            _id: string,
            updatedAt: Date,
            createdAt: Date,
            lastLoggedIn: Date,
            isUserApproved: true,
            authLevel: string,
            country: string,
            surname: string,
            name: string,
            password: null,
            email:string,
            __v: number,
            master:any,
            refresh_token: string
        }
    }
}
const TeamAssesment: React.FC = () => {
    const [country, setCountry] = useState<string>("")
    const [department, setDepartment] = useState<IDepartment>()
    const [listOfDepartments, setListOfDepartments] = useState<IDepartment[]>([])

    const CurrentUser:UserInterface = useSelector((d:GlobalScope):UserInterface=>{
        return d.auth.user;
    })


    useEffect(() => {
      setCountry(CurrentUser.country)
      }, [CurrentUser])

      const [areaManagers, setAreaManagers] = useState<UserInterface[]>([]);
 useEffect(()=>{
   
    (async()=>{
        if(CurrentUser.authLevel === "omni-admin" && country.length > 0){
            try {
                const data:UserInterface[] & IError = await fetchAPI(`/evaluationcmds/area-managers/get/all/${country}`, FetchMethods.GET)
                if(data.statusCode){
                    setAreaManagers([]);
                    //@ts-ignore
                    throw new Error(data.message.toString())
                }
                setAreaManagers(data)
            } catch (error) {
                console.log(error)
            }
        }
       
    })()
 }, [country, CurrentUser.authLevel])

 const [selectedAreaManager, setSelectedAreaManager] = useState<string>("");
 
 const getDataByAreaManager = async (index:number)=>{
    setListOfDepartments([]);
    try {

        let user = areaManagers[index];
        const managersDepartments: IDepartment[] & IError = await fetchAPI(`/evaluationcmds/get/unique/departments/of/master`, FetchMethods.POST,{user});
        if(managersDepartments.statusCode ){
                setListOfDepartments([]);
                throw new Error(managersDepartments.message?.toString());
        }
        setListOfDepartments(managersDepartments);
        setSelectedAreaManager(user._id);
    } catch (error) {
        
    }
 }

 useEffect(()=>{
    if(CurrentUser.authLevel === "area-manager"){
        (async()=>{
            try {
                const managersDepartments: IDepartment[] & IError = await fetchAPI(`/evaluationcmds/get/unique/departments/of/master`, FetchMethods.POST,{user:CurrentUser});
                if(managersDepartments.statusCode ){
                        setListOfDepartments([]);
                        throw new Error(managersDepartments.message?.toString());
                }
                setListOfDepartments(managersDepartments);
                setSelectedAreaManager(CurrentUser._id);
            } catch (error) {
               console.log(error) 
            }
        })()
       
    }
 }, [CurrentUser])
    return (
      <AdminContainer>
           {
        (CurrentUser && CurrentUser.authLevel === "omni-admin") &&
        <div className="row">
    <div className="col border p-2 mx-2 shadow rounded">
    <CountryFilter returnedCountry={(country:string)=>{setCountry(country)}}/>
    </div>
    {
    (country.length > 0 && country) && (
        <div className="col border p-2 mx-2 shadow rounded">
            <H2 className="h3">Area managers</H2>
            <select onChange={({target})=>{
                getDataByAreaManager(parseInt(target.value));
            }}
            className='form-control'
            >
                <option className='form-control' value={-1}>---</option>
                {
                    areaManagers.map((areaManager:UserInterface, index:number)=>{
                        return <option className="form-control" key={index} value={index}>{areaManager.name} {areaManager.surname} - {areaManager.department?.name}
                        </option>
                    })
                }
            </select>
        </div>
    )
    } 
    <div className='col'>
        {
            listOfDepartments.length > 0 && <div className="col border p-2 mx-2 shadow rounded">
                <H2 className="h3">Select Department</H2>
                <select onChange={({target})=>{
                    if(parseInt(target.value) === -2){
                        setDepartment({
                            name: "All of the above",
                            _id: "all",
                            country: "all",
                            createdAt: new Date(),
                            updatedAt: new Date(),
                            description: "All of the above",
                            creator: "all",
                        })
                    }else{
                        setDepartment(listOfDepartments[parseInt(target.value)])
                    }
                }}
                className='form-control'
                >
                    <option defaultValue={-1} className='form-control' value={-1}>---</option>
                    {
                        listOfDepartments.map((department:IDepartment, index:number)=>{
                            return <option className="form-control" key={index} value={index}>{department.name}
                            </option>
                        })
                    }
                     {
                    listOfDepartments.length > 1 && 
                    <option value={-2}><span className='color-primary'>All of the above</span></option>
                }
                </select>
            </div>
        }
    </div>
</div>}

{/* 
SELECT DEP FOR AM
*/
CurrentUser.authLevel === "area-manager" &&
<div className='col my-5'>
{
    listOfDepartments.length > 0 && <div className="col border p-2 mx-2 shadow rounded">
        <H2 className="h3">Select Department</H2>
        <select onChange={({target})=>{
            if(parseInt(target.value) === -2){
                setDepartment({
                    name: "All of the above",
                    _id: "all",
                    country: "all",
                    createdAt: new Date(),
                    updatedAt: new Date(),
                    description: "All of the above",
                    creator: "all",
                })
            }else{
                setDepartment(listOfDepartments[parseInt(target.value)])
            }
            
        }}
        className='form-control'
        >
            <option defaultValue={-1} className='form-control' value={-1}>---</option>
            {
                listOfDepartments.map((department:IDepartment, index:number)=>{
                    return <option className="form-control" key={index} value={index}>{department.name}
                    </option>
                })
            }
{
            listOfDepartments.length > 1 && 
            <option value={-2}><span className='color-primary'>All of the above</span></option>
        }
        </select>
       
    </div>
}
</div>

}

{
    department && <div className="row">
    <div className="col">
        {
          ( selectedAreaManager.length > 0 && department) && 
          <TeamAssessmentDetailComponent master={selectedAreaManager} department={department} />
        }
        
    </div>
</div>
}
    </AdminContainer>
  );
};

export default TeamAssesment;




