import { useState, useEffect } from "react";
import AdminContainer from "../../../admin-components/AdminContainer";

import { IDepartment } from "../../../interface/IDepartment";

import { useDispatch, useSelector } from "react-redux";
import { setError } from "../../../store/reducers/errorReducer";
import { UserInterface } from "../../../interface/UserInterface";
import { fetchAPI } from "../../../utils/FetchApi";
import { FetchMethods } from "../../../interface/Enums/methods.enum";

import Dropdown from "react-bootstrap/Dropdown";
import { BsFillBriefcaseFill, BsThreeDotsVertical } from "react-icons/bs";
import Badge from "react-bootstrap/Badge";
import Accordion from "react-bootstrap/Accordion";

import CountryFilter from "../../../components/CountryFilter";

import { BtnCreate, H1, H3 } from "../../../components/StyledComponents";
interface CountryInterface {
  name: string;
  createdAt: Date;
  updatedAt: Date;
  _id: string;
}
const ManageDepartment = () => {
  const dispatch = useDispatch();
  const data: UserInterface = useSelector((data: any) => {
    return data.auth.user;
  });

  const makeEmpty = {
    name: "",
    country: "",
    description: "",
    creator: data.name + " " + data.surname,
    createdAt: new Date(),
  };
  const [departments, setDepartments] = useState<IDepartment[]>([]);
  const [showAddDepartment, setshowAddDepartment] = useState<boolean>(false);
  const [Department, setDepartment] = useState<IDepartment>(makeEmpty);
  const [countries, setCountries] = useState<CountryInterface[]>([]);
  const [makeChange, setMakeChange] = useState<boolean>(false);

  const [showUpdate, setShowUpdate] = useState<boolean>(false);
  const [updatedDeparment, setUpdatedDeparment] =
    useState<IDepartment>(makeEmpty);
  const [selectedCountry, setSelectedCountry] = useState<string>("---");
  useEffect(() => {
    (async () => {
      try {
        const countries: CountryInterface[] = await fetchAPI(
          "/admincmds/get-all-countries",
          FetchMethods.GET
        );
        countries.sort((a, b) => (a.name > b.name ? 1 : -1));
        setCountries(countries);
        setDepartment(d=>({
          ...d,
          creator: data.name + " " + data.surname,
        }))
      } catch (error: any) {
        dispatch(setError(error.message.toString()));
      }
    })();
  }, [data, dispatch]);

  const createDepartment = async () => {
    const { name, country, creator, description } = Department;
    try {
      const data: IDepartment = await fetchAPI(
        "/admincmds/create-department",
        FetchMethods.POST,
        {
          name,
          country,
          creator,
          description,
        }
      );
      if (data.hasOwnProperty("error")) {
        throw new Error(data.message?.toString());
      }
      setDepartment(makeEmpty);
      setMakeChange(!makeChange);
    } catch (error: any) {
      dispatch(setError(error.toString()));
    }
  };

  useEffect(() => {
    (async () => {
      //GET ALL DEPARTMENTS
      try {
        const data: IDepartment[] | any = await fetchAPI(
          `/admincmds/get-all-departments/${selectedCountry}`,
          FetchMethods.GET
        );
        if (data.hasOwnProperty("error")) {
          throw new Error(data.message?.toString());
        }
        setshowAddDepartment(false);
        setDepartments(data);
      } catch (error: any) {
        dispatch(setError(error.toString()));
      }
    })();
  }, [makeChange, dispatch, selectedCountry]);

  const deleteDepartment = async (index: number) => {
    //confifrm delete

    if(!window.confirm("Are you sure you want to delete this department?"))return;
    try {
      const EvalToBeDeleted: IDepartment = await fetchAPI(
        `/admincmds/delete-department/${departments[index]._id}`,
        FetchMethods.GET
      );
      if (data.hasOwnProperty("error")) {
        throw new Error(EvalToBeDeleted.message?.toString());
      }
      setMakeChange(!makeChange);
    } catch (error: any) {
      dispatch(setError(error.toString()));
    }
  };

  const updateDepartment = async () => {
    try {
      const getUpdatedDepartments: IDepartment[] = await fetchAPI(
        "/admincmds/update-department",
        FetchMethods.PATCH,
        updatedDeparment
      );
      setDepartments(getUpdatedDepartments);
      setShowUpdate(false);
      setUpdatedDeparment(makeEmpty);
    } catch (error) {
      dispatch(setError(JSON.stringify(error)));
    }
  };

  const scrollToBottom = () => {
    window.scrollTo({ top: window.innerHeight });
  };
  const pickCountry = (country: string) => {
    setSelectedCountry(country);
  };
  return (
    <AdminContainer>
      <div className="row">
        <H1 className="h1">Manage Departments</H1>
      </div>
      <div className="row justify-content-between align-items-center">
        <div className="col-lg-8 m-2">
        <CountryFilter returnedCountry={pickCountry} />
        </div>
        <div className="col-lg-2 m-2 p-1">
            <BtnCreate onClick={() => {
                setshowAddDepartment(!showAddDepartment)
              }}>
          Create new Department
            </BtnCreate>
        </div>
      </div>
      <hr className="hr" />
      <div className="row justify-content-md-center">
        {departments.map((c: IDepartment, i: number) => (
          <div key={c._id} className="col-5 shadow rounded p-2 m-2">
          <div className="row rounded" key={i}>
            <div className="col-sm-2">
              <Dropdown>
                <Dropdown.Toggle size="sm" variant="info">
                  <BsThreeDotsVertical />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    href="#/action-1"
                    onClick={() => {
                      scrollToBottom();
                      setshowAddDepartment(false);
                      setShowUpdate(true);
                      setUpdatedDeparment(departments[i]);
                    }}
                  >
                    Update
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#/action-2"
                    onClick={() => {
                      deleteDepartment(i);
                    }}
                  >
                    Delete
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-sm-10">
              <Accordion>
                <Accordion.Item eventKey={i.toString()}>
                  <Accordion.Header>
                    <BsFillBriefcaseFill />
                    {"   "}
                    <small className="m-2" style={{ fontWeight: "bold" }}>
                      {c.name}
                    </small>
                  </Accordion.Header>

                  <Accordion.Body key={i}>
                    <strong>Description:</strong>
                    <p>{c.description}</p>
                    <br />
                    <Badge bg="secondary">By: {c.creator}</Badge>
                    <br />
                    <Badge bg="secondary">Country scope: {c.country}</Badge>
                    <br />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
          </div>
        ))}
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          createDepartment();
        }}
      >
        <div className="row" style={{ marginTop: 100 }}>
          <div className="form-group">
          
            {showAddDepartment && (
              <div className="card" style={{ padding: 30 }}>
                <H3>Department Name</H3>
                <input
                 className="form-control"
                  type="text"
                  value={Department.name}
                  onChange={({ target }) => {
                    setDepartment({ ...Department, name: target.value });
                  }}
                />
                <H3>Department description</H3>
                <input
                  className="form-control"
                  type="text"
                  value={Department.description}
                  onChange={({ target }) => {
                    setDepartment({ ...Department, description: target.value });
                  }}
                />
                <H3>Country</H3>
                <select
                 className="form-control"
                  onChange={({ target }) => {
                    setDepartment({ ...Department, country: target.value });
                  }}
                >
                  <option className="form-control" value="---">---</option>
                  {countries.map((c: CountryInterface, i: number) => (
                    <option  className="form-control" key={i} value={c.name}>
                      {c.name}
                    </option>
                  ))}
                </select>

                <br />
                <input
                 className="form-control"
                  type="text"
                  readOnly
                  value={Department.creator}
                  style={{ backgroundColor: "#d6d6d6" }}
                />
                <br />
                <button type="submit" className="dai-btn dai-btn-success text-white font-bold"> 
                  Create new Department
                </button>
              </div>
            )}
          </div>
        </div>
      </form>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          updateDepartment();
        }}
      >
        <div className="row" style={{ marginTop: 100 }}>
          <div className="form-group">
            {showUpdate && (
              <div className="card" style={{ padding: 30 }}>
                <p
                  onClick={() => {
                    setShowUpdate(false);
                  }}
                >
                  Close update component
                </p>
                <h2>Update {updatedDeparment._id}</h2>
                <H3>Section Name</H3>
                <input
                 className="form-control"
                  type="text"
                  autoFocus
                  value={updatedDeparment.name}
                  onChange={({ target }) => {
                    setUpdatedDeparment({
                      ...updatedDeparment,
                      name: target.value,
                    });
                  }}
                />
                <H3>Deparment Description</H3>
                <input
                 className="form-control"
                  type="text"
                  value={updatedDeparment.description}
                  onChange={({ target }) => {
                    setUpdatedDeparment({
                      ...updatedDeparment,
                      description: target.value,
                    });
                  }}
                />

                <H3>Country</H3>
                <select
                 className="form-control"
                  onChange={({ target }) => {
                    setUpdatedDeparment({
                      ...updatedDeparment,
                      country: target.value,
                    });
                  }}
                >
                  <option  className="form-control" value={updatedDeparment.country}>
                    {updatedDeparment.country}
                  </option>
                  {countries.map((c: CountryInterface, i: number) => (
                    <option key={i} value={c.name}>
                      {c.name}
                    </option>
                  ))}
                </select>
                <br />
                <input
                 className="form-control"
                  type="text"
                  readOnly
                  value={updatedDeparment.creator}
                  style={{ backgroundColor: "#d6d6d6" }}
                />
                <br />
                <button type="submit" className="btn btn-danger">
                  Update {updatedDeparment.name}
                </button>
              </div>
            )}
          </div>
        </div>
      </form>
    </AdminContainer>
  );
};

export default ManageDepartment;
