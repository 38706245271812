import { FC, useEffect, useState } from 'react'
import { IOpenQuestion, IRangeQuestion } from '../../interface/Bidirectional/interface';
import { fetchOpenQuestionsByUser, deleteOpenQuestion, fetchRangeQuestionsByUser, deleteRangeQuestion } from '../../APIs/bidirectional';
import { AccordionComp, P, InfoBox, BtnDelete, BtnUpdate } from '../../components/StyledComponents';
interface Props {
    refetch?: boolean;
    setRefetch?: () => void;
    setUpdate?: (question: IOpenQuestion | IRangeQuestion) => void;
}
const QuestionsList: FC<Props> = ({ refetch, setRefetch, setUpdate }) => {
    const [openQuestions, setOpenQuestions] = useState<IOpenQuestion[]>([])
    const [rangeQuestions, setRangeQuestions] = useState<IRangeQuestion[]>([])

    useEffect(() => {
        (
            async () => {
                const res = await fetchOpenQuestionsByUser();
                const res2 = await fetchRangeQuestionsByUser();
                setOpenQuestions(res)
                setRangeQuestions(res2)          
            }
        )()
    }, [refetch])

    return (
        <>
        {/* OPEN QUESTIONS LIST */}
            {openQuestions.length > 0 && <AccordionComp title='Open Questions' className='col-span-12 shadow-sm border-2 rounded-lg'>
                <InfoBox>
                    <small>
                        Here you can see all the open questions you have created. Open Questions are simple - a user can freerly answer to the specific question.
                    </small>
                </InfoBox>
                {openQuestions.map((question, index) => {
                    return (
                        <AccordionComp key={index} title={question.question} className='bg-gray-100 p-2 my-2'>
                            <P>
                                <strong>Description: </strong>{question.description} <br />
                            </P>
                            <hr />
                            <P>
                                <small>
                                    <strong>Created: </strong>{question.createdAt && question.createdAt.toString()} <br />
                                    <strong>Type: </strong>{question.type}<br />
                                    <strong>Creator: </strong> {question.creator && question.creator} <br />
                                </small>
                            </P>
                            {
                                question._id &&
                                <div className='flex flex-row gap-2'>
                                    <BtnDelete onClick={async () => {
                                        const confirm = window.confirm('Are you sure you want to delete this question?');
                                        if (!confirm) {
                                            return;
                                        }
                                        if (!question._id) {
                                            return;
                                        }
                                        await deleteOpenQuestion(question._id);
                                        setRefetch && setRefetch();
                                    }}>Delete</BtnDelete>

                                    <BtnUpdate onClick={()=>{
                                        setUpdate && setUpdate(question);
                                    }}>Update</BtnUpdate>
                                </div>
                            }

                        </AccordionComp>
                    )
                })}
            </AccordionComp>}
        {/* RANGE QUESTIONS LIST */}
            {rangeQuestions.length > 0 && <AccordionComp title='Range Questions' className='col-span-12 shadow-sm border-2 rounded-lg'>
                <InfoBox>
                    <small>
                        Here you can see all the range questions you have created. Range Questions are simple too - a user must select a number between minimum and maximum.
                    </small>
                </InfoBox>
                {rangeQuestions.map((question, index) => {
                    return (
                        <AccordionComp key={index} title={question.question} className='bg-gray-100 p-2 my-2'>
                            <P>
                                <strong>Description: </strong>{question.description} <br />
                                <strong>Minimum value: </strong>{question.min} <br />
                                <strong>Maximum value: </strong>{question.max} <br />
                            </P>
                            <hr />
                            <P>
                                <small>
                                
                                    <strong>Created: </strong>{question.createdAt && question.createdAt.toString()} <br />
                                    <strong>Type: </strong>{question.type}<br />
                                    <strong>Creator: </strong> {question.creator && question.creator} <br />
                                </small>
                            </P>
                            {
                                question._id &&
                                <div className='flex flex-row gap-2'>
                                    <BtnDelete onClick={async () => {
                                        const confirm = window.confirm('Are you sure you want to delete this question?');
                                        if (!confirm) {
                                            return;
                                        }
                                        if (!question._id) {
                                            return;
                                        }
                                        await deleteRangeQuestion(question._id);
                                        setRefetch && setRefetch();
                                    }}>Delete</BtnDelete>

                                    <BtnUpdate onClick={()=>{
                                        setUpdate && setUpdate(question);
                                    }}>Update</BtnUpdate>
                                </div>
                            }

                        </AccordionComp>
                    )
                })}
            </AccordionComp>}
        </>
    )
}

export default QuestionsList