import { FC } from "react"
import { IOpenQuestion } from "../../../interface/Bidirectional/interface"
import { createOpenQuestion, updateOpenQuestion } from "../../../APIs/bidirectional";
interface IOpenQuestionProps {
    question: IOpenQuestion;
    setter: (question: IOpenQuestion) => void;
    refetchQuestions?: () => void;
}
const OpenQuestion:FC<IOpenQuestionProps> = ({question, setter, refetchQuestions}) => {

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (question._id) {
            await updateOpenQuestion(question._id, question);
        }else{
            await createOpenQuestion(question);
        }
        refetchQuestions && refetchQuestions();
    }
    return (
        <div className="w-full max-w-full mx-auto mb-5">
            <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" onSubmit={onSubmit}>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
                       Question
                    </label>
                    <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="How do you rate your... "
                    onChange={(e) => {
                        setter({
                            ...question,
                            question: e.target.value
                        })
                    }}
                    value={question.question}
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
                       Description
                    </label>
                    <textarea className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="description" placeholder="Describe your question" rows={5}
                    onChange={(e) => {
                        setter({
                            ...question,
                            description: e.target.value
                        })
                    }}
                    value={question.description}
                    />
                </div>
                <input className="btn btn-success btn-small bg-green-500" type="submit" value={question._id ? "Update question" : "Create question"} />
            </form>
        </div>
    )
}
export default OpenQuestion