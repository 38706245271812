import { FC } from 'react';
import Modal from 'react-bootstrap/Modal';
import { H2, Btn } from './StyledComponents';
interface Props {
  show: boolean;
  handleClose: () => void;
  title?:string,
  children?: React.ReactNode,
  className?: string
}
const ApparisalModal:FC<Props> = ({show, handleClose,title, children, className}) => {
  return (
      <Modal show={show} onHide={handleClose} className={`${className}`} size='xl'>
        <Modal.Header closeButton>
          <Modal.Title><H2>{title ? title : "Title is missing"}</H2></Modal.Title>
        </Modal.Header>
        <Modal.Body>{
          children}</Modal.Body>
        <Modal.Footer>
          <Btn onClick={handleClose}>
            Close
          </Btn>
        </Modal.Footer>
      </Modal>
  )
};
export default ApparisalModal;