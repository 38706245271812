import { useEffect, useState } from "react"
import { fetchAPI } from "../utils/FetchApi"
import { FetchMethods } from "../interface/Enums/methods.enum"
import { IError } from "../interface"


const GlobalFooter = () => {
const [version, setVersion] = useState<string|undefined>()
useEffect(() => {
  (async()=>{
    try{
      const data:IError & {version:string} = await fetchAPI("/versioncontrol", FetchMethods.GET);
      console.log("Wtf")
      console.log(data)
      if(data){
        setVersion(data.version)
      }
    }catch(error:any){
      console.log(error)
    }
  })()
}, [])

  return (
<div className="flex flex-col">
  <main className="flex-1">
    {/* Your main content here */}
  </main>
  
  <footer className="w-full bg-white p-2 text-center">
    <small className="text-sm text-gray-500 text-center">
     {new Date().getFullYear()} - FrontEnd version 0.0.10 | BackEnd version {version ? version : "couldn't reach server"}
    </small>
  </footer>
</div>

  )
}

export default GlobalFooter