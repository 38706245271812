
import { useSelector, useDispatch } from "react-redux";
import React, { useState } from "react";
import validator from "validator";
import { authUserSuccess, authUserFailed } from "../store/reducers/auth";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FormErrors from "../atoms_die/forms/FormErrors";
import { ResponseInterface } from "../interface/ResponseInterface"
import { GLOBAL_URL } from "../GLOBAL_URL";
import { H1 } from "../components/StyledComponents";
const Login = () => {
    /**
     * CONST
     */
    const navigate = useNavigate();
    const dispatch = useDispatch();

    /**
     * Global state
     */
    const authState = useSelector((data: any) => { return data.auth; })

    /**
     * State hooks
     */
    const [email, setEmail] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [errorStatus, setErrorStatus] = useState<boolean>(true);
    /**
     * USE EFFECT
     */
    useEffect(() => { if (authState.isAuthenticated) { navigate("/dashboard") } }, [authState, navigate]);

    const submitForm = async (e: any) => {
        e.preventDefault();
        if (!validator.isEmail(email)) {
            setErrorMessage("Email is missing or this is invalid email")
            setErrorStatus(false)
        } else if (password.length < 1 && password.length > 5) {
            setErrorMessage("Password is missing or its length is lesser than 6 characters")
            setErrorStatus(false)
        }
        else {
            //save email into local storage
            localStorage.setItem("email", email);
            try {
                const response: Response = await fetch(GLOBAL_URL + "/auth/signin", {
                    headers: { 'Content-Type': 'application/json' },
                    method: "post",
                    body: JSON.stringify({ email, password })
                })
                const data: ResponseInterface = await response.json();
                if (data.message) {
                    //@ts-ignore
                    throw new Error(data.message.response.message)
                }
                if (data.statusCode) {
                    if (data.statusCode > 300) {
                        throw new Error(data.error + " | " + data.message)
                    }
                }

                if (!data.user) throw new Error("Could not fetch user's data")
                if (!data.user.isUserApproved) throw new Error("User was not approved by any authority yet!")
                localStorage.setItem("token", data.tokens.access_token);
                dispatch(authUserSuccess({ token: data.tokens.access_token, user: data.user }));
            } catch (error: any) {
                setErrorStatus(false);
                setErrorMessage(error.message)
                localStorage.removeItem("token");
                dispatch(authUserFailed())
            }
        }
    };

    //useEffect that loads email from local storage

    useEffect(() => {
        const email = localStorage.getItem("email");
        if (email) {
            setEmail(email);
        }
    }, [])

    return (

        <div className="row d-flex justify-content-center align-items-center" style={{
            borderRadius: 22,
        }}>


            <div className="col-12 my-20 gap-10">
                <img src="https://www.gedeonrichter.com/cz/-/media/sites/global/images/rg-logo.svg?rev=51d2bf419e7042dc84c331e40ee5228e&hash=20C504592A520F637FBFB4FA7723D5B1" className="mx-auto" alt="Official Gedeon Richter logo" />
                <H1 className="text-center mb-10">Evaluation Platform</H1>
                <br />
                <br />
                <form onSubmit={submitForm} className="d-sm-flex flex-column w-100 p-3 justify-content-center align-items-center shadow-[0px_-50px_76px_-31px_rgba(0,_0,_0,_0.1)]" style={{
                    margin: "0 auto",
                    background: "white",
                    padding: 20,
                    borderRadius: 22,
                    maxWidth: 600,
                }}>

                    {/* EMAIL */}

                    <div className="input-group mb-3">
                        <input onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setEmail(e.target.value) }} type="email" className="form-control" placeholder="email" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                        <div className="input-group-append">
                            <span className="input-group-text" id="basic-addon2">@ e-mail</span>
                        </div>
                    </div>

                    {/* PASSWORD */}

                    <div className="input-group mb-3">
                        <input onChange={(e: React.ChangeEvent<HTMLInputElement>): void => { setPassword(e.target.value) }} type="password" className="form-control" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                        <div className="input-group-append">
                            <span className="input-group-text" id="basic-addon2">Password</span>
                        </div>
                    </div>

                    <input style={{ minWidth: 300 }} className="btn btn-info hover:scale-105 transition-all duration-500 ease-in-out font-[Flower] text-4xl" type="submit" value="LOGIN" />
                </form>

            </div>



            {/* ERRORS */}
            <p style={{ color: "red", textAlign: "center" }}>
                {!errorStatus && <FormErrors error={errorMessage} ></FormErrors>}
            </p>
        </div>
    );
};

export default Login;