import React from "react";
import { Accordion, Badge, Table } from "react-bootstrap";
import { BsBug, BsBugFill, BsInfo, BsShieldFillCheck } from "react-icons/bs";
import AdminContainer from "../admin-components/AdminContainer";
import { H1, Section } from "../components/StyledComponents";
interface Props {}

const Updates: React.FC<Props> = () => {
  return (
    <AdminContainer>
        <Section>
            <H1>Evaluation Platform - updates</H1>
            <Accordion className="border p-2 my-2 rounded">
            <Accordion.Header>Version: 0.0.8</Accordion.Header>
            <Accordion.Body>
            <p>
                    <em>
                       This updates contains new features and bug fixes.
                    </em>
                </p>
                <Table responsive className="table">
                    <tbody>
                        <tr>
                            <th className="text-info">Components/Route &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                            <th className="text-info">Update Description</th>
                            <th className="text-info">Severity &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                            <th className="text-info">Type &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                        </tr>
                        <tr>
                            <td><strong>Create Appraisal Document</strong></td>
                            <td>Create new evaluation period was changed to Create new Appraisal Document<br />
                            </td>
                            <td><Badge className="bg-success">Low</Badge></td>
                            <td><BsBug /> Bug</td>
                        </tr>
                       
                        <tr>
                            <td><strong>Create Appraisal Document</strong></td>
                            <td>Stability of the component was significantly improved.<br />
                            </td>
                            <td><Badge className="bg-warning">Moderate</Badge></td>
                            <td><BsBug /> Bug</td>
                        </tr>
                        <tr>
                            <td><strong>Evaluation - Appraisal Document</strong></td>
                            <td>Load historical evaluations for the colleague - naming fix. <br />
                            </td>
                            <td><Badge className="bg-success">Low</Badge></td>
                            <td><BsBug /> Bug</td>
                        </tr>
                        <tr>
                            <td><strong>Evaluation - Appraisal Document</strong></td>
                            <td>Periods that were already applied to the colleague can not be selected anymore!<br />
                            </td>
                            <td><Badge className="bg-warning">Moderate</Badge></td>
                            <td><BsShieldFillCheck /> Feature</td>
                        </tr>
                        <tr>
                            <td><strong>Team statistics and assesment</strong></td>
                            <td>Team statistics and assesment were completely re-done. Now they support all possible features.<br />
                            </td>
                            <td><Badge className="bg-danger">Critical</Badge></td>
                            <td><BsShieldFillCheck /> Feature</td>
                        </tr>
                        
                       
                    </tbody>
                </Table>
                </Accordion.Body>
        </Accordion>
        <Accordion className="border p-2 my-2 rounded">
            <Accordion.Header>Version: 0.0.7</Accordion.Header>
            <Accordion.Body>
            <p>
                    <em>
                       This updates contains new features and bug fixes.
                    </em>
                </p>
                <Table responsive className="table">
                    <tbody>
                        <tr>
                            <th className="text-info">Components/Route &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                            <th className="text-info">Update Description</th>
                            <th className="text-info">Severity &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                            <th className="text-info">Type &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                        </tr>
                        <tr>
                            <td><strong>Create annual evaluation</strong></td>
                            <td>Typo in ordering items.<br />
                          
                            </td>
                            <td><Badge className="bg-success">Low</Badge></td>
                            <td><BsBug /> Bug</td>
                        </tr>
                        <tr>
                            <td><strong>Global Menu</strong></td>
                            <td>Menu for AM/SM and MR was improved. <br />
                            </td>
                            <td><Badge className="bg-success">Low</Badge></td>
                            <td><BsBug /> Bug</td>
                        </tr>

                        <tr>
                            <td><strong>User detail - appraisal document</strong></td>
                            <td>Inputs and Outputs were better aligned.<br />
                            </td>
                            <td><Badge className="bg-success">Low</Badge></td>
                            <td><BsBug /> Bug</td>
                        </tr>
                        <tr>
                            <td><strong>User detail - annual evaluation</strong></td>
                            <td>On user's detail you can now switch between the standard view and the Excel view. If Excel view is selected, you are allowed to print the evaluation.<br />
                            </td>
                            <td><Badge className="bg-warning">Moderate</Badge></td>
                            <td><BsShieldFillCheck /> Feature</td>
                        </tr>
                        
                       
                    </tbody>
                </Table>
                </Accordion.Body>
        </Accordion>
        <Accordion className="border p-2 my-2 rounded">
            <Accordion.Header>Version: 0.0.6</Accordion.Header>
            <Accordion.Body>
            <p>
                    <em>
                       This updates contains new features and bug fixes.
                    </em>
                </p>
                <Table responsive className="table">
                    <tbody>
                        <tr>
                            <th className="text-info">Components/Route &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                            <th className="text-info">Update Description</th>
                            <th className="text-info">Severity &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                            <th className="text-info">Type &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                        </tr>
                        <tr>
                            <td><strong>Manage Departments</strong></td>
                            <td>Button typo when creating department was fixed - create jobname to create department <br />
                             </td>
                            <td><Badge className="bg-success">Low</Badge></td>
                            <td><BsBugFill/> Bug</td>
                        </tr>
                        <tr>
                            <td><strong>Manage Users</strong></td>
                            <td>User can not approve or disapprove himself/herself anymore<br />
                             </td>
                            <td><Badge className="bg-success">Low</Badge></td>
                            <td><BsBugFill/> Bug</td>
                        </tr>
                        <tr>
                            <td><strong>Manage Users</strong></td>
                            <td>When registering a new user, czech translation was replaced for english (odeslat to register)<br />
                             </td>
                            <td><Badge className="bg-success">Low</Badge></td>
                            <td><BsBugFill/> Bug</td>
                        </tr>
                        <tr>
                            <td><strong>Manage Inputs</strong></td>
                            <td>Better validation rules: <em>name, country, creator, explanation must not be empty and importance must have a value from 1 to 100</em>
                             </td>
                             <td><Badge className="bg-warning">Moderate</Badge></td>
                            <td><BsBugFill/> Bug</td>
                        </tr>
                        <tr>
                            <td><strong>Manage Outputs</strong></td>
                            <td>Better validation rules: <em>name, country, creator, explanation must not be empty and importance must have a value from 1 to 100</em>
                             </td>
                             <td><Badge className="bg-warning">Moderate</Badge></td>
                            <td><BsBugFill/> Bug</td>
                        </tr>
                        <tr>
                            <td><strong>Manage Comments</strong></td>
                            <td>Better validation rules: <em>name, country and explanation must not be empty</em>
                             </td>
                             <td><Badge className="bg-warning">Moderate</Badge></td>
                            <td><BsBugFill/> Bug</td>
                        </tr>
                        <tr>
                            <td><strong>Manage Competencies</strong></td>
                            <td>Better validation rules: <em>name, country and explanation must not be empty</em>
                             </td>
                             <td><Badge className="bg-warning">Moderate</Badge></td>
                            <td><BsBugFill/> Bug</td>
                        </tr>
                        <tr>
                            <td><strong>Login window</strong></td>
                            <td><em>When users sets invalid credentials, the server returns non-generic eror like [object Object]</em>
                             </td>
                             <td><Badge className="bg-success">Low</Badge></td>
                            <td><BsBugFill/> Bug</td>
                        </tr>
                        <tr>
                            <td><strong>Create Appraisal</strong></td>
                            <td><em>Stability of the whole process was checked and minor parts were improved. Nothing serious that should cause the system's failure was found.</em>
                             </td>
                             <td><Badge className="bg-secondary">None</Badge></td>
                            <td><BsInfo size={24}/> Info</td>
                        </tr>
                        <tr>
                            <td><strong>Create Appraisal</strong></td>
                            <td><em>If "import previous" was selected, all metrics got duplicated. This was fixed!</em>
                             </td>
                             <td><Badge className="bg-warning">Moderate</Badge></td>
                            <td><BsBugFill/> Bug</td>
                        </tr>
                        <tr>
                            <td><strong>Create Appraisal</strong></td>
                            <td><em>You can now sort selected Inputs, Outputs, Competencies and Comments!</em>
                             </td>
                             <td><Badge className="bg-warning">Moderate</Badge></td>
                            <td><BsShieldFillCheck /> Feature</td>
                        </tr>
                        <tr>
                            <td><strong>Manage Users</strong></td>
                            <td><em>Manage Users was significantly reworked. Now there are two views - Standard and Hierarchial view. Both views are for admins's better user experience.</em>
                             </td>
                             <td><Badge className="bg-danger">Critical</Badge></td>
                            <td><BsShieldFillCheck /> Feature</td>
                        </tr>
                     
                        <tr>
                            <td><strong>Team Statistics</strong></td>
                            <td><em>Warning message was removed.</em>
                             </td>
                             <td><Badge className="bg-secondary">None</Badge></td>
                            <td><BsInfo size={24}/> Info</td>
                        </tr>
                        <tr>
                            <td><strong>Team Assessment</strong></td>
                            <td><em>Core required feature "Team Assesment" was implemented into a system!</em>
                             </td>
                             <td><Badge className="bg-danger">Critical</Badge></td>
                            <td><BsShieldFillCheck /> Feature</td>
                        </tr>
                    </tbody>
                </Table>
                </Accordion.Body>
        </Accordion>
        <Accordion className="border p-2 my-2 rounded">
            <Accordion.Header>Version: 0.0.5</Accordion.Header>
            <Accordion.Body>
            <p>
                    <em>
                       This updates contains new features and bug fixes.
                    </em>
                </p>
                <Table responsive className="table">
                    <tbody>
                        <tr>
                            <th className="text-info">Components/Route &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                            <th className="text-info">Update Description</th>
                            <th className="text-info">Severity &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                            <th className="text-info">Type &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                        </tr>
                        <tr>
                            <td><strong>Manage Users</strong></td>
                            <td>Administrators can now access anyone's evaluation history <br />
                            <img src={require("../assets/visual_update.png")} alt="visual update" style={{maxWidth:"100%"}} className="shadow border rounded my-5 p-2"/>
                            </td>
                            <td><Badge className="bg-success">Low</Badge></td>
                            <td><BsShieldFillCheck /> Feature</td>
                        </tr>
                        <tr>
                            <td><strong>Team Statistics</strong></td>
                            <td>Administrators can now access anyone's team statistics. Area managers have access to only team's data. <br />
                            </td>
                            <td><Badge className="bg-danger">Critical</Badge></td>
                            <td><BsShieldFillCheck /> Feature</td>
                        </tr>
                        <tr>
                            <td><strong>Login window</strong></td>
                            <td>On Safari and some other mobile browsers email had to be written all over again. Now the email is pre-loaded from the browser's memory..<br />
                            </td>
                            <td><Badge className="bg-success">Low</Badge></td>
                            <td><BsShieldFillCheck /> Feature</td>
                        </tr>
                        <tr>
                            <td><strong>Appriasal Document - Input, Ouput - no default value</strong></td>
                            <td>
                                Based on the feedback, the default value for input and output is now empty. It was previously a "0", but users on iPad had problems. <br />
                            </td>
                            <td><Badge className="bg-success">Low</Badge></td>
                            <td><BsShieldFillCheck /> Feature</td>
                        </tr>
                        <tr>
                            <td><strong>User's dashboard</strong></td>
                            <td>
                                The user's dashboard was reworked to make it more user friendly. 
                            </td>
                            <td><Badge className="bg-success">Low</Badge></td>
                            <td><BsShieldFillCheck /> Feature</td>
                        </tr>
                       
                    </tbody>
                </Table>
                </Accordion.Body>
        </Accordion>
        <Accordion className="border p-2 my-2 rounded">
            <Accordion.Header>Version: 0.0.8</Accordion.Header>
            <Accordion.Body>
            <p>
                    <em>
                        This update contains stability and security fixes. 
                    </em>
                </p>
                <table className="table">
                    <tbody>
                        <tr>
                            <th className="text-info">Components/Route &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                            <th className="text-info">Update Description</th>
                            <th className="text-info">Severity &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                            <th className="text-info">Type &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                        </tr>
                        <tr>
                            <td><strong>Appraisal Evaluation</strong></td>
                            <td>calculation formula for inputs, outputs and competencies has been updated.</td>
                            <td><Badge className="bg-danger">Critical</Badge></td>
                            <td><BsBugFill/> Bug</td>
                        </tr>
                        <tr>
                            <td><strong>Appraisal Evaluation</strong></td>
                            <td>Manager is no longer required to select colleague, department, jobname and many other things. Such data are loaded directly from the colelague's profile.</td>
                            <td><Badge className="bg-warning">Moderate</Badge></td>
                            <td><BsShieldFillCheck /> Feature</td>
                        </tr>
                        <tr>
                            <td><strong>Appraisal Evaluation</strong></td>
                            <td>User interface was reworked to make it more user friendly.</td>
                            <td><Badge className="bg-warning">Moderate</Badge></td>
                            <td><BsShieldFillCheck /> Feature</td>
                        </tr>
                        <tr>
                            <td><strong>Appraisal History</strong></td>
                            <td>Colleagues with adminstrator role can now delete every single Appraisal Evaluation document. </td>
                            <td><Badge className="bg-warning">Moderate</Badge></td>
                            <td><BsShieldFillCheck /> Feature</td>
                        </tr>
                        <tr>
                            <td><strong>User management</strong></td>
                            <td>User interface was reworked to make it more user friendly.</td>
                            <td><Badge className="bg-success">Low</Badge></td>
                            <td><BsShieldFillCheck /> Feature</td>
                        </tr>
                        <tr>
                            <td><strong>User management</strong></td>
                            <td>For specific user you can add now start date of employment, current position held since</td>
                            <td><Badge className="bg-warning">Moderate</Badge></td>
                            <td><BsShieldFillCheck /> Feature</td>
                        </tr>
                        <tr>
                            <td><strong>User management</strong></td>
                            <td>For specific user you can check wheter the user consented with GDPR</td>
                            <td><Badge className="bg-warning">Moderate</Badge></td>
                            <td><BsShieldFillCheck /> Feature</td>
                        </tr>
                        <tr>
                            <td><strong>User management</strong></td>
                            <td>stability issues were fixed.</td>
                            <td><Badge className="bg-danger">Critical</Badge></td>
                            <td><BsBugFill/> Bug</td>
                        </tr>
                        <tr>
                            <td><strong>Manage inputs</strong></td>
                            <td>Create button updated.</td>
                            <td><Badge className="bg-success">Low</Badge></td>
                            <td><BsBugFill/> Bug</td>
                        </tr>
                        <tr>
                            <td><strong>Manage outputs</strong></td>
                            <td>Create button updated.</td>
                            <td><Badge className="bg-success">Low</Badge></td>
                            <td><BsBugFill/> Bug</td>
                        </tr>
                        <tr>
                            <td><strong>Manage competencies</strong></td>
                            <td>Create button updated.</td>
                            <td><Badge className="bg-success">Low</Badge></td>
                            <td><BsBugFill/> Bug</td>
                        </tr>
                    </tbody>
                </table>
                </Accordion.Body>
        </Accordion>
        <Accordion className="border p-2 my-2 rounded">
            <Accordion.Header>Version: 0.0.3</Accordion.Header>
            <Accordion.Body>
                <p>
                    <em>
                        This update contains first wave of GDPR implementation
                    </em>
                </p>
                <ol>
                    <li>
                        <strong>GDPR</strong> - Huge GDPR component and functionality was added to the system
                    </li>
                    
                </ol>
                </Accordion.Body>
        </Accordion>
        </Section>
    </AdminContainer>
  );
};

export default Updates;
