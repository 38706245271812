import { fetchAPI } from "../../utils/FetchApi";
import { FetchMethods } from "../../interface/Enums/methods.enum";

//[Nest] 1644  - 03/04/2025, 12:27:49 PM     LOG [RouterExplorer] Mapped {/users/master, GET} route +0ms

export const fetchUsersByMaster = async ():Promise<any> => {
    const users = await fetchAPI("/users/master", FetchMethods.GET);
    return users;
}

//[Nest] 3159  - 03/31/2025, 8:58:18 AM     LOG [RouterExplorer] Mapped {/users/all/:country, GET} route +0ms
export const fetchAllUsersByCountry = async (country:string):Promise<any> => {
    const users = await fetchAPI(`/users/all/${country}`, FetchMethods.GET);
    return users;
}
// [Nest] 96322  - 03/12/2025, 7:49:24 AM     LOG [RouterExplorer] Mapped {/users/:id, GET} route +0ms

export const fetchUserById = async (id:string):Promise<any> => {
    const user = await fetchAPI(`/users/${id}`, FetchMethods.GET);
    return user;
}

//[Nest] 74936  - 03/12/2025, 3:17:03 PM     LOG [RouterExplorer] Mapped {/bidirectional/evaluation, POST} route +0ms

export const createBidirectionalEvaluation = async (data:any):Promise<any> => {
    const response = await fetchAPI("/bidirectional/evaluation", FetchMethods.POST, data);
    return response;
}

//[Nest] 74936  - 03/12/2025, 3:17:03 PM     LOG [RouterExplorer] Mapped {/bidirectional/evaluation/user/:id, GET} route +0ms

export const getBidirectionalEvaluationsByUser = async (id:string):Promise<any> => {
    const response = await fetchAPI(`/bidirectional/evaluation/user/${id}`, FetchMethods.GET);
    return response;
}