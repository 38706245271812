import { FC } from 'react'
import { TypeQuestion } from '../../../interface/Bidirectional/type/Question.type'

interface Props {
    questions: TypeQuestion[]
}
const QuestionsAndAnswers:FC<Props> = ({questions}) => {
  return (
    <>
        {
            questions.map((question, index) => {
                return (
                    <div key={index} className='bg-gray-100 p-2 my-2'>
                        <p><strong>Question: </strong>{question.question}</p>
                        <p><strong>Answer: </strong>{question.answer}</p>
                    </div>
                )
            }
            )
        }
    </>
  )
}

export default QuestionsAndAnswers