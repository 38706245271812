import React, { FC, useState } from 'react'
import { IBidirectionalTemplate } from '../../../interface/Bidirectional/interface'
import { updateTemplate, createTemplate } from '../../../APIs/bidirectional';
import QuestionsPicker from '../QuestionsPicker';
import UserPicker from '../../../components/User/UserPicker';
import { TypeQuestion } from '../../../interface/Bidirectional/type/Question.type';
import { IUser } from '../../../interface/IUser.interface';
import { H3 } from '../../../components/StyledComponents';
import CalendarPicker from '../../../components/CalendarPicker';
interface Props {
    template: IBidirectionalTemplate;
    setTemplate: (template: IBidirectionalTemplate) => void;
    refreshTemplates: () => void;
}
const Template: FC<Props> = ({ template, setTemplate, refreshTemplates }) => {
    const [questions, setQuestions] = useState<TypeQuestion[]>([]);
    const [whoCanEvaluate, setWhoCanEvaluate] = useState<IUser[]>([]);
    const [whoWillBeEvaluated, setWhoWillBeEvaluated] = useState<IUser[]>([]);
    const [date, setDate] = useState(new Date());
    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        if (template._id) {
            await updateTemplate(template._id, template);
        } else {
            /**
             * I Will have to put some guards here - u know, good old validation
             */
            if (!template.name || !template.description || !questions.length || !whoWillBeEvaluated.length || !whoCanEvaluate.length) {
                alert("Please fill all the fields");
                return;
            }
            const filteredWhoCanEvaluate = whoCanEvaluate.filter((selected => selected.selected)).map(u => u._id) as string[];
            const filteredWhoWillBeEvaluated = whoWillBeEvaluated.filter((selected => selected.selected)).map(u => u._id) as string[];

            if (filteredWhoWillBeEvaluated.length > 1 && filteredWhoCanEvaluate.length > 1) {
                alert("You can't have multiple evaluators and multiple evaluated at the same time");
                return;
            }
            const finalTemplate = {
                ...template,
                questions: questions.filter((selected => selected.selected)),
                whoCanEvaluate: filteredWhoCanEvaluate,
                whoWillBeEvaluated: filteredWhoWillBeEvaluated,
                expiration: date
            }
            await createTemplate(finalTemplate);
        }
        refreshTemplates();
    }
    return (
        <div className="w-full max-w-full mx-auto mb-5">
            <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" onSubmit={onSubmit}>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="templatename">
                        <H3>Template Name</H3>
                    </label>
                    <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="templatename" type="text" placeholder="Unique template name... "
                        onChange={(e) => {
                            setTemplate({
                                ...template,
                                name: e.target.value
                            })
                        }}
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
                        <H3>Description</H3>
                    </label>
                    <textarea className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="description" placeholder="Explain the goal of this template." rows={5}
                        onChange={(e) => {
                            setTemplate({
                                ...template,
                                description: e.target.value
                            })
                        }}
                        value={template.description}
                    />
                </div>
                <div className="mb-4">
                    <H3>Select questions you want to use!</H3>
                    <QuestionsPicker setQuestions={setQuestions} questions={questions} />
                </div>

                <div className="mb-4">
                    <H3>Who will evaluate?</H3>
                    <UserPicker setUsers={setWhoCanEvaluate} users={whoCanEvaluate} />
                </div>
                <div className="mb-4">
                    <H3>Who will be evaluated?</H3>
                    <UserPicker setUsers={setWhoWillBeEvaluated} users={whoWillBeEvaluated} />
                </div>
                <div className="mb-4">

                    <CalendarPicker date={date} setDate={setDate} />
                </div>
                <input className="btn btn-success btn-small bg-green-500 mt-10" type="submit" value={template._id ? "Update template" : "Create template"} />
            </form>
        </div>
    )
}

export default Template