
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { FetchMethods } from "../interface/Enums/methods.enum";
import { UserInterface } from "../interface";
import { setError } from "../store/reducers/errorReducer";
import { fetchAPI } from "../utils/FetchApi";
import UserDetailCard from "./AreaManagers/UserDetailCard";
import MedicalRepDashboard from "./MedicalRepresentativeRoutes/MedicalRepDashboard";
import { Accordion } from "react-bootstrap";
import TeamStatistics from "./AdminRoutes/TeamStatistics";
import TeamAssesment from "./AdminRoutes/TeamAssesment";
import { H1, H2, Section, P } from "../components/StyledComponents";
import AssignedManager from "./MedicalRepresentativeRoutes/AssignedManager";
import BidirectionalHistory from "./BidirectionalAppraisal/BidirectionalHistory";
interface GlobalScope {

    auth: {
        user: {
            _id: string,
            updatedAt: Date,
            createdAt: Date,
            lastLoggedIn: Date,
            isUserApproved: true,
            authLevel: string,
            country: string,
            surname: string,
            name: string,
            password: null,
            email: string,
            __v: number,
            master: any,
            refresh_token: string
        }
    }
}

const Dashboard = () => {
    const dispatch = useDispatch()
    const data: UserInterface = useSelector((d: GlobalScope): UserInterface => {
        return d.auth.user;
    })
    const [users, setUsers] = useState<UserInterface[]>([])

    useEffect(() => {
        if (data._id && data._id.length > 0) {
            (async () => {
                try {
                    const getmyUsers: UserInterface[] = await fetchAPI(`/users/master/${data._id}`, FetchMethods.GET);
                    if (getmyUsers.hasOwnProperty("error")) {
                        //@ts-ignore
                        throw new Error(getmyUsers.message.toString())

                    }
                    setUsers(getmyUsers)
                } catch (error: any) {
                    dispatch(setError(error.message))
                }

            })()
        }
    }, [data, dispatch])

    const [authLevel, setAuthLevel] = useState<string | undefined>(undefined);

    useEffect(() => {
        setAuthLevel(data.authLevel)
    }, [data.authLevel])
    return (
        <Section>
            <div className="container">
                {(users.length > 0 && authLevel !== "medical-representative") ?
                    <>
                        {data && <H1>Hi, {data.name} {data.surname}</H1>}
                        <P>Down below you can find the list of your colleagues!</P>
                        <Accordion className="rounded border p-1 shadow">
                            <Accordion.Header>
                                <h5 className="h5">Your colleagues</h5>
                            </Accordion.Header>
                            <Accordion.Body>
                                <UserDetailCard users={users} />
                            </Accordion.Body>
                        </Accordion>
                        <Accordion className="rounded border p-1 my-2 shadow">
                            <Accordion.Header>
                                <h5 className="h5">{`${data.name}'s team statistics`}</h5>
                            </Accordion.Header>
                            <Accordion.Body>
                                {
                                    (data.authLevel === "omni-admin" || data.authLevel === "area-manager") && <TeamStatistics name={``} />
                                }
                            </Accordion.Body>
                        </Accordion>
                        <Accordion className="rounded border p-1 my-2 shadow">
                            <Accordion.Header>
                                <h5 className="h5">{`${data.name}'s team assesment`}</h5>
                            </Accordion.Header>
                            <Accordion.Body>
                                {
                                    (data.authLevel === "omni-admin" || data.authLevel === "area-manager") && <TeamAssesment />
                                }
                            </Accordion.Body>
                        </Accordion>
                        <Accordion className="rounded border p-1 my-2 shadow">
                            <Accordion.Header>
                                <h5 className="h5">Bidirectional History</h5>
                            </Accordion.Header>
                            <Accordion.Body>
                                <BidirectionalHistory medrep={data} />
                            </Accordion.Body>
                        </Accordion>

                    </>
                    : <div>{authLevel !== "medical-representative" ? <div>Seems like no users are assigned to you!</div> : <></>}</div>}

                {
                    (authLevel === "medical-representative" || authLevel === "area-manager") &&
                    <>
                        <hr className="hr" />
                        <div className="max-w-2xl">
                            <AssignedManager />
                        </div>
                        <H2 className="my-5">Your evaluations</H2>
                        <MedicalRepDashboard medrepid={data._id} />
                        <Accordion className="rounded border p-1 my-2 shadow">
                            <Accordion.Header>
                                <h5 className="h5">History - Bidireactional evaluation</h5>
                            </Accordion.Header>
                            <Accordion.Body>
                                <BidirectionalHistory medrep={data} />
                            </Accordion.Body>
                        </Accordion>

                    </>

                }
            </div>
        </Section>

    )
}

export default Dashboard