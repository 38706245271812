import { H1, Section, AccordionComp as Accordion, Grid12, H3 } from '../../components/StyledComponents'
import { useEffect, useState, FC } from 'react'
import { IUser } from '../../interface/IUser.interface'
import { UserInterface } from '../../interface'
import { useParams } from 'react-router-dom'
import { fetchUserById } from '../../APIs/user'
import { getBidirectionalEvaluationsByUser } from '../../APIs/user'
import { IBidirectionalEvaluation, IRangeQuestion } from '../../interface/Bidirectional/interface'
import QuestionsAndAnswers from './components/QuestionsAndAnswers'
import RangeQuestionChart from '../../components/Charts/RangeQuestion.chart'
import { useSelector } from 'react-redux'
interface Props {
    medrep?: UserInterface
}
const BidirectionalHistory:FC<Props> = ({medrep}) => {
    const { id } = useParams<{ id: string }>();
    const [user, setUser] = useState<IUser | null>(null)
    const [evaluations, setEvaluations] = useState<IBidirectionalEvaluation[]>([])
    const [rangeQuestions, setRangeQuestions] = useState<IRangeQuestion[]>([]);

    const userData = useSelector((state: any) => state.auth.user) as IUser;

    useEffect(() => {
        (
            async () => {
                if (!id) {
                    return
                }
               fetchFunction(id)

            }
        )()
    }, [id])


    useEffect(() => {

        if (!medrep && id){
            return;
        }
        if (medrep?._id) fetchFunction(medrep?._id);
   
    }, [medrep])

    const fetchFunction = async (id:string) =>{
        const {authLevel, _id} = userData;
        if (authLevel === "omni-admin" || _id == id){
            const user = await fetchUserById(id);
            setUser(user)
    
            const evaluations = await getBidirectionalEvaluationsByUser(id);
    
            setEvaluations(evaluations)
    
            const filterRangeQuestions = evaluations.map((evaluation: IBidirectionalEvaluation) => {
                return evaluation.questions.filter((question) => question.type === 'range') as IRangeQuestion[]
            }
            ).flat();
            setRangeQuestions(filterRangeQuestions)
        }else{
            return
        }
       
    }
    return (
        <Section>
            <H1>Bidirectional History for {user?.name} {user?.surname}</H1>
            <Grid12>
                {
                    evaluations.length > 0 ? evaluations.map((evaluation: IBidirectionalEvaluation, index: number) => {
                        const { name, surname } = evaluation.evaluatedBy as IUser;
                        return (

                            <Accordion key={index} title={`${evaluation.template.name}`} className='col-span-12 md:col-span-6 lg:col-span-6 shadow-md gap-5 my-2'>
                                <H3>Questions & Answers</H3>
                                <QuestionsAndAnswers questions={evaluation.questions} />
                                <RangeQuestionChart questions={rangeQuestions} />
                                <hr />
                                <p>Evaluated on: {evaluation.createdAt?.toString()}</p>
                                <p>Evaluated  by: {name} {surname}</p>
                            </Accordion>

                        )
                    }
                    ) : <p className='col-span-12'>No evaluations found...</p>
                }
            </Grid12>
        </Section>
    )
}

export default BidirectionalHistory