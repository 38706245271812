import React, { FC } from 'react'
import { BsFillInfoCircleFill, BsFilePlus, BsTrash2Fill, BsCloudArrowUpFill } from "react-icons/bs";
import Accordion from "react-bootstrap/Accordion";

interface H1Props extends React.HTMLAttributes<HTMLHeadingElement> {
    children: string | React.ReactNode;
}

export const H1: React.FC<H1Props> = ({ children, className, ...props }) => {
    return (
        <h1 className={`${className} text-5xl font-[Pacifico] my-2 py-4 text-gradient border-b-2`} {...props}>
            {children}
        </h1>
    );
};

interface H2Props extends React.HTMLAttributes<HTMLHeadingElement> {
    children: string | React.ReactNode;
}

export const H2: React.FC<H2Props> = ({ children, className, ...props }) => {
    return (
        <h2 className={`${className} text-3xl my-1 py-2 text-gradient font-[Pacifico]`} {...props}>
            {children}
        </h2>
    );
};

interface H3Props extends React.HTMLAttributes<HTMLHeadingElement> {
    children: string | React.ReactNode;
}

export const H3: React.FC<H3Props> = ({ children, className, ...props }) => {
    return (
        <h3 className={`${className} text-xl my-1 py-2 font-bold`} {...props}>
            {children}
        </h3>
    );
}

interface SectionProps extends React.HTMLAttributes<HTMLElement> {
    children: React.ReactNode;
}

export const Section: React.FC<SectionProps> = ({ children, className, ...props }) => {
    return (
        <section className={`${className} w-full py-10`} {...props}>
            <div className="mx-auto">
                {children}
            </div>
        </section>
    );
};

interface ParagraphProps extends React.HTMLAttributes<HTMLParagraphElement> {
    children?: string | React.ReactNode;
}

export const P = ({ children, className, ...props }: ParagraphProps) => {
    return (
        <p className={`${className} text-xl my-3 `} {...props}>
            {children}
        </p>
    );
}

interface InfoBoxProps extends React.HTMLAttributes<HTMLDivElement> {
    children: string | React.ReactNode;
}

export const InfoBox: React.FC<InfoBoxProps> = ({ children, className, ...props }) => {
    return (
        <div className={`${className} p-4 bg-gray-100 border-l-4 border-blue-500 grid grid-cols-1 md:grid-cols-12 gap-2 justify-center align-middle`} {...props}>
            <div className="col-span-1"><BsFillInfoCircleFill className='text-5xl mx-auto' /></div>
            <div className="col-span-11 text-xl self-center">{children}</div>
        </div>
    );
}

interface BtnCreateProps extends React.HTMLAttributes<HTMLButtonElement> {
    children: string | React.ReactNode;
}

export const BtnCreate: React.FC<BtnCreateProps> = ({ children, className, ...props }) => {
    return (
        <button className={`${className} bg-green-600 hover:bg-green-300 text-white font-bold py-2 px-4 rounded flex flex-row gap-1 justify-center align-middle my-2 transition-all duration-700 ease-out`} {...props}>
            <BsFilePlus className='text-7xl self-center' /> <div className='self-center text-2xl'>{children}</div>
        </button>
    );
}

export const Btn: React.FC<BtnCreateProps> = ({ children, className, ...props }) => {
    return (
        <button className={`${className} bg-blue-600 hover:bg-blue-300 text-white font-bold py-2 px-4 rounded flex flex-row gap-1 justify-center align-middle my-2 transition-all duration-700 ease-out`} {...props}>
            {children}
        </button>
    );
}

export const BtnDelete: React.FC<BtnCreateProps> = ({ children, className, ...props }) => {
    return (
        <button className={`${className} bg-red-600 hover:bg-red-300 text-white font-bold py-2 px-4 rounded flex flex-row gap-1 justify-center align-middle my-2 transition-all duration-700 ease-out`} {...props}>
            <BsTrash2Fill className='text-2xl self-center' /> <div className='self-center text-xl uppercase'>{children}</div>
        </button>
    );
}
export const BtnUpdate: React.FC<BtnCreateProps> = ({ children, className, ...props }) => {
    return (
        <button className={`${className} bg-purple-400 hover:bg-red-300 text-white font-bold py-2 px-4 rounded flex flex-row gap-1 justify-center align-middle my-2 transition-all duration-700 ease-out`} {...props}>
            <BsCloudArrowUpFill className='text-2xl self-center' /> <div className='self-center text-xl uppercase'>{children}</div>
        </button>
    );
}

interface GridProps12 extends React.HTMLAttributes<HTMLDivElement> {
    children?: React.ReactNode;
}

export const Grid12 = ({ children, className, ...props }: GridProps12) => {
    return (
        <div className={`${className} grid grid-cols-1 md:grid-cols-12 gap-2 justify-center align-middle w-full`} {...props}>
            {children}
        </div>
    );
}

interface AccordionProps extends React.HTMLAttributes<HTMLDivElement> {
    children?: React.ReactNode;
    className?: string;
    title?: string;
    titleClassName?: string;
}

export const AccordionComp: FC<AccordionProps> = ({ children, className, title, titleClassName }) => {
    return (
        <Accordion className={className}>
            <Accordion.Header className={`font-bold text-2xl ${titleClassName}`}>{title}</Accordion.Header>
            <Accordion.Body>
                {children}
            </Accordion.Body>
        </Accordion>
    );
}

interface IBadgeOpenQuestion extends React.HTMLAttributes<HTMLSpanElement> {
    children: string | React.ReactNode;
}

export const BadgeOpenQuestion: React.FC<IBadgeOpenQuestion> = ({ children, className, ...props }) => {
    return (
        <span className={`${className} bg-blue-500 text-white font-bold py-1 px-2 rounded text-xs`}>
            {children}
        </span>
    );
}