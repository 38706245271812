import { FC, useEffect, useState } from 'react'
import { IBidirectionalTemplate } from '../../../interface/Bidirectional/interface'
import { AccordionComp as Accordion, InfoBox, H3, P, Grid12, Btn } from '../../../components/StyledComponents'
import { TypeQuestion } from '../../../interface/Bidirectional/type/Question.type'
import { createBidirectionalEvaluation } from '../../../APIs/user'
import { IBidirectionalEvaluation } from '../../../interface/Bidirectional/interface'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { IUser } from '../../../interface/IUser.interface'
interface Props {
    template: IBidirectionalTemplate,
    evaluatedColleageID: string
}
const EvaluationByUser: FC<Props> = ({ template,evaluatedColleageID }) => {
    const [answeredTemplate, setAnsweredTemplate] = useState<IBidirectionalTemplate>({ ...template })
    const navigate = useNavigate()
     const userData = useSelector((state: any) => state.auth.user) as IUser;
    useEffect(()=>{
        setAnsweredTemplate({...answeredTemplate, evaluatedColleage:evaluatedColleageID})
    },[evaluatedColleageID])

    const submitEvaluation = async ()=> {

        const confirm = window.confirm("Are you sure you want to submit the evaluation?")
        if(!confirm){
            return
        }


        if(answeredTemplate.questions.length === 0){
            return
        }

        //could not load colleague that is being evaluated
        if(answeredTemplate.evaluatedColleage === ""){
            return
        }

        //template isnt available
        if(answeredTemplate._id === ""){
            return
        }

        //Check that each question has an answer
        const hasAnswer:any[] = answeredTemplate.questions.filter((question)=>{
            console.log(question)
            return question.answer === ""
        })

        console.log(hasAnswer)

        if(hasAnswer.length > 0){
            alert("Please answer all questions before submitting the evaluation")
            return
        }

        const evaluation:IBidirectionalEvaluation = {
            template: answeredTemplate,
            questions: answeredTemplate.questions,
            evaluatedColleage: evaluatedColleageID,
        }

        await createBidirectionalEvaluation(evaluation)
        
        alert("Evaluation submitted successfully");

        
        navigate(`/`)
    }
    return (
        <Accordion title={`${template.name} by ${template.expiration}`} className='shadow-sm rounded-lg my-2'>
            <InfoBox>
                <p>{template.description}</p>
            </InfoBox>
            {
                template.questions.length > 0 && template.questions.map((question, index) => {
                    if (question.type === "open-question") {
                        return (
                            <div key={index} className='border-1 border-gray-200 p-2 rounded-xl m-2'>
                                <H3>{index + 1}. {question.question}</H3>
                                <P>{question.description}</P>
                                <textarea className="dai-textarea shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="description" placeholder="Answer the question" rows={5}
                                    onChange={(e) => {
                                        const updatedQuestions = [...answeredTemplate.questions];
                                        updatedQuestions[index] = {
                                            ...updatedQuestions[index],
                                            answer: e.target.value
                                        } as TypeQuestion; // Explicitly assert type
                                        setAnsweredTemplate({ ...answeredTemplate, questions: updatedQuestions });
                                    }}
                                    value={answeredTemplate.questions[index].answer}
                                />
                            </div>
                        )
                    }

                    if (question.type === "range") {
                        return (
                            <div key={index} className='border-1 border-gray-200 p-2 rounded-xl m-2'>
                                <H3>{index + 1}. {question.question}</H3>
                                <P>{question.description}</P>
                                <Grid12 className='align-center items-center justify-center gap-5'>
                                    <input type="range" min={question.min} max={question.max} className="dai-range shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline col-span-10" id="description" placeholder="Answer the question"
                                        onChange={(e) => {
                                            const updatedQuestions = [...answeredTemplate.questions];
                                            updatedQuestions[index] = {
                                                ...updatedQuestions[index],
                                                answer: e.target.value
                                            } as TypeQuestion; // Explicitly assert type
                                            setAnsweredTemplate({ ...answeredTemplate, questions: updatedQuestions });
                                        }}
                                        value={answeredTemplate.questions[index].answer | 0}
                                    />
                                    <div className="col-span-2 text-align-center text-3xl">
                                        {answeredTemplate.questions[index].answer}
                                    </div>
                                </Grid12>
                            </div>
                        )
                    }
                    
                    return <></>
                })

            }
            <Btn className="" onClick={()=>{
                submitEvaluation();
            }}>Finish evaluation</Btn>
        </Accordion>
    )
}

export default EvaluationByUser