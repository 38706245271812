import { useState, useEffect } from 'react'
import { Section, H1, H2, H3 } from '../../components/StyledComponents'
import { IUser } from '../../interface/IUser.interface'
import { useParams } from 'react-router-dom'
import { fetchUserById } from '../../APIs/user'
import { fetchTemplatesByAssignedUser } from '../../APIs/bidirectional'
import { IBidirectionalTemplate } from '../../interface/Bidirectional/interface'
import EvaluationByUser from "./components/EvaluationByUser"
const Evaluate = () => {
    const { id } = useParams<{ id: string }>()
    const [user, setUser] = useState<IUser | null>(null)
    const [templates, setTemplates] = useState<IBidirectionalTemplate[] | null>(null)

    useEffect(() => {
        (
            async () => {
                if (!id) {
                    return
                }
                const user = await fetchUserById(id);
                setUser(user)
                const usersTemplates = await fetchTemplatesByAssignedUser(id);
                setTemplates(usersTemplates)
            }
        )()
    }, [id])
    return (
        <Section>
            {
                user ? <H1>Bidirectional evaluation </H1> : <H1>No user found...</H1>
            }
            {
                templates && templates.length > 0 ?
                <>
                    <H2>List of evaluations that are assigned to {user?.name} {user?.surname}</H2>
                    {(templates.length > 0 && id) && templates.map((template: IBidirectionalTemplate, index: number) => <EvaluationByUser key={index} template={template} evaluatedColleageID={id} />)}</> : <H3>No evaluations found... or they are not assign to you!</H3>
            }
        </Section>
    )
}

export default Evaluate