import { fetchAPI } from "../../utils/FetchApi";
import { FetchMethods } from "../../interface/Enums/methods.enum";
import { IOpenQuestion, IRangeQuestion, IBidirectionalTemplate } from "../../interface/Bidirectional/interface";

//{/bidirectional/openquestion, POST}
export const createOpenQuestion = async (question: IOpenQuestion):Promise<any> => {
    const createdQuestion = await fetchAPI("/bidirectional/openquestion", FetchMethods.POST, question);
    return createdQuestion;
}
//{/bidirectional/openquestion/user, GET} route
export const fetchOpenQuestionsByUser = async ():Promise<any> => {
    const questions = await fetchAPI("/bidirectional/openquestion/user", FetchMethods.GET);
    return questions;
}
// [Nest] 43605  - 03/03/2025, 6:28:28 AM     LOG [RouterExplorer] Mapped {/bidirectional/openquestion/country, GET} route +0ms
export const fetchOpenQuestionsByUserCountry = async ():Promise<any> => {
    const questions = await fetchAPI("/bidirectional/openquestion/country", FetchMethods.GET);
    return questions;
}
// [Nest] 43605  - 03/03/2025, 6:28:28 AM     LOG [RouterExplorer] Mapped {/bidirectional/openquestion/:id, GET} route +1ms
export const fetchOpenQuestionById = async (id:string):Promise<any> => {
    const question = await fetchAPI(`/bidirectional/openquestion/${id}`, FetchMethods.GET);
    return question;
}
// [Nest] 43605  - 03/03/2025, 6:28:28 AM     LOG [RouterExplorer] Mapped {/bidirectional/openquestion, GET} route +0ms
export const fetchAllOpenQuestions = async ():Promise<any> => {
    const questions = await fetchAPI("/bidirectional/openquestion", FetchMethods.GET);
    return questions;
}
// [Nest] 43605  - 03/03/2025, 6:28:28 AM     LOG [RouterExplorer] Mapped {/bidirectional/openquestion/:id, PATCH} route +0ms
export const updateOpenQuestion = async (id:string, question: IOpenQuestion):Promise<any> => {
    const updatedQuestion = await fetchAPI(`/bidirectional/openquestion/${id}`, FetchMethods.PATCH, question);
    return updatedQuestion;
}
// [Nest] 43605  - 03/03/2025, 6:28:28 AM     LOG [RouterExplorer] Mapped {/bidirectional/openquestion/:id, DELETE} route +0ms
export const deleteOpenQuestion = async (id:string):Promise<any> => {
    const deletedQuestion = await fetchAPI(`/bidirectional/openquestion/${id}`, FetchMethods.DELETE);
    return deletedQuestion;
}

// [Nest] 76340  - 03/03/2025, 10:21:41 AM     LOG [RouterExplorer] Mapped {/bidirectional/rangequestion, POST} route +0ms
export const createRangeQuestion = async (question: IRangeQuestion):Promise<any> => {
    const createdQuestion = await fetchAPI("/bidirectional/rangequestion", FetchMethods.POST, question);
    return createdQuestion;
}
// [Nest] 76340  - 03/03/2025, 10:21:41 AM     LOG [RouterExplorer] Mapped {/bidirectional/rangequestion/user, GET} route +0ms
export const fetchRangeQuestionsByUser = async ():Promise<any> => {
    const questions = await fetchAPI("/bidirectional/rangequestion/user", FetchMethods.GET);
    return questions;
}
// [Nest] 76340  - 03/03/2025, 10:21:41 AM     LOG [RouterExplorer] Mapped {/bidirectional/rangequestion/country, GET} route +0ms
export const fetchRangeQuestionsByUserCountry = async ():Promise<any> => {
    const questions = await fetchAPI("/bidirectional/rangequestion/country", FetchMethods.GET);
    return questions;
}
// [Nest] 76340  - 03/03/2025, 10:21:41 AM     LOG [RouterExplorer] Mapped {/bidirectional/rangequestion/:id, GET} route +0ms
export const fetchRangeQuestionById = async (id:string):Promise<any> => {
    const question = await fetchAPI(`/bidirectional/rangequestion/${id}`, FetchMethods.GET);
    return question;
}
// [Nest] 76340  - 03/03/2025, 10:21:41 AM     LOG [RouterExplorer] Mapped {/bidirectional/rangequestion, GET} route +0ms
export const fetchAllRangeQuestions = async ():Promise<any> => {
    const questions = await fetchAPI("/bidirectional/rangequestion", FetchMethods.GET);
    return questions;
}
// [Nest] 76340  - 03/03/2025, 10:21:41 AM     LOG [RouterExplorer] Mapped {/bidirectional/rangequestion/:id, PATCH} route +0ms
export const updateRangeQuestion = async (id:string, question: IRangeQuestion):Promise<any> => {
    const updatedQuestion = await fetchAPI(`/bidirectional/rangequestion/${id}`, FetchMethods.PATCH, question);
    return updatedQuestion;
}
// [Nest] 76340  - 03/03/2025, 10:21:41 AM     LOG [RouterExplorer] Mapped {/bidirectional/rangequestion/:id, DELETE} route +0ms
export const deleteRangeQuestion = async (id:string):Promise<any> => {
    const deletedQuestion = await fetchAPI(`/bidirectional/rangequestion/${id}`, FetchMethods.DELETE);
    return deletedQuestion;
}
// [Nest] 87013  - 03/04/2025, 2:55:41 PM     LOG [RouterExplorer] Mapped {/bidirectional/template, POST} route +0ms

export const createTemplate = async (template: IBidirectionalTemplate):Promise<any> => {
    const createdTemplate = await fetchAPI("/bidirectional/template", FetchMethods.POST, template);
    return createdTemplate;
}
// [Nest] 87013  - 03/04/2025, 2:55:41 PM     LOG [RouterExplorer] Mapped {/bidirectional/template/:id, PATCH} route +0ms
export const updateTemplate = async (id:string, template: IBidirectionalTemplate):Promise<any> => {
    const updatedTemplate = await fetchAPI(`/bidirectional/template/${id}`, FetchMethods.PATCH, template);
    return updatedTemplate;
}
// [Nest] 87013  - 03/04/2025, 2:55:41 PM     LOG [RouterExplorer] Mapped {/bidirectional/template/user, GET} route +0ms
export const fetchTemplatesByUser = async ():Promise<any> => {
    const templates = await fetchAPI("/bidirectional/template/user", FetchMethods.GET);
    return templates;
}
// [Nest] 87013  - 03/04/2025, 2:55:41 PM     LOG [RouterExplorer] Mapped {/bidirectional/template/country, GET} route +0ms
export const fetchTemplatesByUserCountry = async ():Promise<any> => {
    const templates = await fetchAPI("/bidirectional/template/country", FetchMethods.GET);
    return templates;
}
// [Nest] 87013  - 03/04/2025, 2:55:41 PM     LOG [RouterExplorer] Mapped {/bidirectional/template/:id, GET} route +0ms
export const fetchTemplateById = async (id:string):Promise<any> => {
    const template = await fetchAPI(`/bidirectional/template/${id}`, FetchMethods.GET);
    return template;
}
// [Nest] 63817  - 03/12/2025, 9:02:25 AM     LOG [RouterExplorer] Mapped {/bidirectional/template/assigned/:id, GET} route +0ms
export const fetchTemplatesByAssignedUser = async (id:string):Promise<any> => {
    const templates = await fetchAPI(`/bidirectional/template/assigned/${id}`, FetchMethods.GET);
    return templates;
}
// [Nest] 87013  - 03/04/2025, 2:55:41 PM     LOG [RouterExplorer] Mapped {/bidirectional/template, GET} route +0ms
export const fetchAllTemplates = async ():Promise<any> => {
    const templates = await fetchAPI("/bidirectional/template", FetchMethods.GET);
    return templates;
}
// [Nest] 87013  - 03/04/2025, 2:55:41 PM     LOG [RouterExplorer] Mapped {/bidirectional/template/:id, DELETE} route +0ms
export const deleteTemplate = async (id:string):Promise<any> => {
    const deletedTemplate = await fetchAPI(`/bidirectional/template/${id}`, FetchMethods.DELETE);
    return deletedTemplate;
}
//[Nest] 48273  - 03/19/2025, 12:05:49 PM     LOG [RouterExplorer] Mapped {/bidirectional/evaluation/template/:id, GET} route +0ms

export const fetchEvaluationByTemplateId = async (id:string):Promise<any> => {
    const evaluations = await fetchAPI(`/bidirectional/evaluation/template/${id}`, FetchMethods.GET);
    return evaluations;
}