import { FC } from "react"
import { IRangeQuestion } from "../../../interface/Bidirectional/interface"
import { createRangeQuestion, updateRangeQuestion } from "../../../APIs/bidirectional";
import { Grid12 } from "../../../components/StyledComponents";
interface IRangeQuestionProps {
    question: IRangeQuestion;
    setter: (question: IRangeQuestion) => void;
    refetchQuestions?: () => void;
}
const RangeQuestion:FC<IRangeQuestionProps> = ({question, setter, refetchQuestions}) => {

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        console.log(question)
        if (question._id) {
       //     await updateOpenQuestion(question._id, question);
       await updateRangeQuestion(question._id, question);
        }else{
          //  await createOpenQuestion(question);
            await createRangeQuestion(question);
        }
        refetchQuestions && refetchQuestions();
    }
    return (
        <div className="w-full max-w-full mx-auto mb-5">
            <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" onSubmit={onSubmit}>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
                       Question
                    </label>
                    <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="How do you rate your... "
                    onChange={(e) => {
                        setter({
                            ...question,
                            question: e.target.value
                        })
                    }}
                    value={question.question}
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
                       Description
                    </label>
                    <textarea className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="description" placeholder="Describe your question" rows={5}
                    onChange={(e) => {
                        setter({
                            ...question,
                            description: e.target.value
                        })
                    }}
                    value={question.description}
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="min">
                       Minimal range value
                    </label>
                    <Grid12>
                    <input type="range" className="dai-range dai-input shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline col-span-11 range" id="min"
                    onChange={(e) => {
                        setter({
                            ...question,
                           min: parseInt(e.target.value)
                        })
                    }}
                    value={question.min}
                    min={0}
                    max={100}
                    />
                    <div className="col-span-1">{question.min}</div>
                    </Grid12>
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
                       Maximal range value
                    </label>
                    <Grid12>
                    <input type="range" className="dai-range dai-input shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline col-span-11 range" id="description" placeholder="Describe your question"
                    onChange={(e) => {
                        setter({
                            ...question,
                           max: parseInt(e.target.value)
                        })
                    }}
                    value={question.max}
                    min={0}
                    max={100}
                    />
                    <div className="col-span-1">{question.max}</div>
                    </Grid12>
                </div>
                <input className="btn btn-success btn-small bg-green-500" type="submit" value={question._id ? "Update question" : "Create question"} />
            </form>
        </div>
    )
}
export default RangeQuestion