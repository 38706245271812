import { FC, useEffect, useState } from 'react'
import { fetchAPI } from '../../../../utils/FetchApi'
import { FetchMethods } from '../../../../interface/Enums/methods.enum'
// import { IEvaluationDynamicDocument } from '../../../../interface'

interface ErrorMessage{
    message:string,
    type: "danger" | "success"
  }
interface LoadDraftsProps {
    id: string,
    repid: string,
    selected: (id: string) => void,
    reloadbyParent: boolean,
    currentDocument: any,
    feedbackMessage: (message:ErrorMessage) => void
}

const LoadDrafts: FC<LoadDraftsProps> = ({ id, selected, reloadbyParent, repid, currentDocument, feedbackMessage }) => {
    const [documents, setDocuments] = useState<any[]>([])
    const [documentsIndex, setDocumentsIndex] = useState<number | null>(null);
    const [reloadFetch, setReloadFetch] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            const data: any = await fetchAPI(`/admincmds/get-all-draft-evaluation-document/${id}/${repid}`, FetchMethods.GET);
            setDocuments(data);
        })()
    }, [id, reloadFetch, reloadbyParent, repid])

    const deleteDraft = async (id: string) => {
        if (documentsIndex === null) {
            return;
        }
        const confirm = window.confirm(`Are you sure you want to delete this draft: ${documents[documentsIndex].draftName}`);
        if (!confirm) {
            return;
        }
        if (documentsIndex === null) {
            return;
        }
        //tady je chyba - potřebuji vědět USER ID (člověk lognutý do systému), repID - člověk, kterého hodnotím a ID dokumentu, který chci smazat. USER ID je 2x.
        //USER, DOC, REP
        await fetchAPI(`/admincmds/delete-draft-evaluation-document/${id}/${documents[documentsIndex]._id}/${repid}`, FetchMethods.DELETE);

        //setDocumentsIndex(null);

        feedbackMessage({message: "Draft deleted successfully", type: "success"})
        setReloadFetch(!reloadFetch);
    }

    const update = async () => {
        //   @Patch('update-draft-evaluation-document/:id')
        if (documentsIndex === null) {
            return;
        }



        await fetchAPI(`/admincmds/update-draft-evaluation-document/${documents[documentsIndex]._id}`, FetchMethods.PATCH, currentDocument);
        feedbackMessage({message: "Draft updated successfully", type: "success"})
        setReloadFetch(!reloadFetch);
    }

    return (
        <>
            {
                documents.length < 1 && <div className="text-center">No Drafts Available</div>
            }
            {
                documents.length > 0 &&
                <div>
                    <h2>Select & load Draft</h2>
                    <div className="row">

                        <select className="form-control" name="" id="" onChange={(e) => {

                            const parsedIndex = parseInt(e.target.value);

                            if (parsedIndex === -1) {
                                setDocumentsIndex(null);
                            } else {
                                setDocumentsIndex(parsedIndex);
                            }


                            if (e.target.value === "-1") {
                                return;
                            }
                            selected(documents[parsedIndex])
                        }}>

                            <option className="form-control" value={-1}>---</option>
                            {
                                documents.map((doc: any, index) => (
                                    <option className="form-control" key={index} value={index}>{doc.draftName}</option>
                                ))
                            }
                        </select>
                        {
                            documentsIndex !== null && <div className="col-12">
                                <button className="btn btn-danger btn-small mt-3 btn-sm" onClick={() => {
                                    deleteDraft(documents[documentsIndex]._id);
                                }}>Delete {documents[documentsIndex].draftName}</button>

                                <button className="btn btn-warning btn-small mt-3 btn-sm" onClick={() => {
                                    update();
                                }}>UPDATE {documents[documentsIndex].draftName}</button>


                            </div>
                        }
                    </div>
                </div>
            }
        </>
    )
}

export default LoadDrafts