import { IEvaluationDynamicDocument } from "../interface"

export const ValidateEvaluationDynamicDocument = (evalDoc:IEvaluationDynamicDocument, callback:Function) =>{
const {
    selectedRep,
    appraisalDocument,
    raitingDistribution,
    inputsStandard,
    inputsEnergizer,
    outputsStandard,
    competencies,
    competenciesLetter,
    competenciesFinaPercentageNumber,
    comments,
    managerConsentButton,
    employeeConsentButton,
    energizerImportanceSum,
    inputImportanceSum,
    inputEnergizerSum,
    inputStandardSum,
    energizerInputFinalValue,
    overallInputFinalvalue,
    energizerInputLetter,
    overallInputLetter,
    outputStandardImportanceSum,
    outputStandardSum,
    outputStandardFinalValue,
    outputStandardLetter,
} = evalDoc;

//Selected rep must exists
if(selectedRep.name.length<1 &&
   selectedRep.email.length<1 &&
   selectedRep.surname.length<1 &&
   selectedRep.country.length<1
   ){
  return callback({
        status:false,
        msg:"Medical reprezentative is missing or the reference object is broken"
    })
}


if(!appraisalDocument.hasOwnProperty("_id")){
    return callback({
        status:false,
        msg:"There is a serious problem with this document - root appraisal document could not be loaded. Please, reload the page and start over."
    })
}

if(!raitingDistribution.hasOwnProperty("a")){
    return callback({
        status:false,
        msg:"Rating distribution integrity is broken, please, reload the document and start over."
    })
}

if(Object.keys(inputsStandard).length === 0){
    return callback({
        status:false,
        msg:"No Inputs were selected for the rep"
    })
}

if(Object.keys(inputsEnergizer).length === 0){
    return callback({
        status:false,
        msg:"No Energizer Inputs were selected for the rep"
    })
}
if(Object.keys(outputsStandard).length === 0){
    return callback({
        status:false,
        msg:"No outputs were selected for the rep"
    })
}
if(Object.keys(competencies).length === 0){
    return callback({
        status:false,
        msg:"No competencies were selected for the rep"
    })
}

if(competenciesLetter.length<0 &&
    energizerInputLetter.length<0 &&
    overallInputLetter.length<0&&
    outputStandardLetter.length<0
    ){
    return callback({
        status:false,
        msg:"No competency was evaluated (checking on final letter)"
    })
}
if(competenciesFinaPercentageNumber === undefined){
    return callback({
        status:false,
        msg:"There is a problem with final competency number"
    })
}
if(Object.keys(comments).length === 0){
    return callback({
        status:false,
        msg:"No comments were selected for the rep"
    })
}


if(!managerConsentButton){
    return callback({
        status:false,
        msg:"Manager must consent with this evaluation document"
    })
}
if(!employeeConsentButton){
    
}

if(
    energizerImportanceSum === undefined &&
    inputImportanceSum === undefined &&
    inputStandardSum === undefined &&
    energizerInputFinalValue=== undefined &&
    overallInputFinalvalue === undefined &&
    outputStandardImportanceSum === undefined &&
    outputStandardSum === undefined &&
    inputEnergizerSum === undefined &&
    outputStandardFinalValue === undefined
    ){
    return callback({
        status:false,
        msg:"There is a problem with importance sum"
    })
}
    
return callback({
    status:true,
        msg:"Vše je ok!"
})
}