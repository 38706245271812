import { FC } from "react";
import { H3 } from "./StyledComponents";

interface Props {
    date: Date;
    setDate: (date: Date) => void;
}

const CalendarPicker: FC<Props> = ({ date, setDate }) => {
    // Convert Date object to YYYY-MM-DD format for input
    const formatDate = (date: Date) => date.toISOString().split("T")[0];

    return (
        <>
            <H3>Select template expiration</H3>
            <input
                type="date"
                className="input"
                value={formatDate(date)}
                onChange={(e) => setDate(new Date(e.target.value))}
            />
        </>

    );
};

export default CalendarPicker;
