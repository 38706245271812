import { FC, useEffect } from 'react'
import { fetchOpenQuestionsByUser, fetchRangeQuestionsByUser } from '../../APIs/bidirectional';
import { AccordionComp, P, Grid12 } from '../../components/StyledComponents';
import { TypeQuestion } from '../../interface/Bidirectional/type/Question.type';
import { BsPlusCircleFill, BsTrashFill } from 'react-icons/bs';
interface Props {
    questions: TypeQuestion[];
    setQuestions: (questions: TypeQuestion[]) => void;
}
const QuestionsPicker: FC<Props> = ({ questions, setQuestions}) => {



    useEffect(() => {
        const fetchAllAvailableQuestions = async () => {
            const res = await fetchOpenQuestionsByUser();
            const res2 = await fetchRangeQuestionsByUser();
            //IF past questions exists, check wheter the question is already in the past questions and if yes set the attribute "selected" to true
            const allQuestionsTogether = [...res, ...res2];
            setQuestions(allQuestionsTogether);
        }
        fetchAllAvailableQuestions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    //Adds a question to the selected questions and removes it from the available questions
    const addQuestion = (index: number) => {
        //Modify the attribute "selected" to true
        questions[index].selected = true;
        //Add the question to the selected questions
        setQuestions([...questions])
    }

    const removeQuestion = (index: number) => {
        //Modify the attribute "selected" to false
        questions[index].selected = false;
        //Remove the question from the selected questions
        setQuestions([...questions])
    }

    const moveQuestionUp = (index: number) => {
        if (index === 0) return;
        const temp = questions[index];
        questions[index] = questions[index - 1];
        questions[index - 1] = temp;
        setQuestions([...questions])
    }

    const moveQuestionDown = (index: number) => {
        if (index === questions.length - 1) return;
        const temp = questions[index];
        questions[index] = questions[index + 1];
        questions[index + 1] = temp;
        setQuestions([...questions])
    }

    return (
        <>
            {/* OPEN QUESTIONS LIST */}
            {questions.length > 0 && questions.map((currentQuestion: TypeQuestion, index: number) => <>
                {currentQuestion.type === "open-question" &&
                    <Grid12>
                        <AccordionComp key={index} title={`${currentQuestion.question} (open question)`} className={`bg-gray-100 my-1 col-span-10 ${currentQuestion.selected && 'bg-green-200'}`}>
                            <P>
                                <strong>Description: </strong>{currentQuestion.description} <br />
                            </P>
                            <hr />
                            <P>
                                <small>
                                    <strong>Created: </strong>{currentQuestion.createdAt && currentQuestion.createdAt.toString()} <br />
                                    <strong>Type: </strong>{currentQuestion.type}<br />
                                    <strong>Creator: </strong> {currentQuestion.creator && currentQuestion.creator} <br />
                                </small>
                            </P>
                        </AccordionComp>
                        <div className="col-span-1 self-center">{
                            currentQuestion.selected ? <div onClick={() => {
                                removeQuestion(index)
                            }} className="btn text-4xl self-center text-red-500"><BsTrashFill /></div> : <div onClick={() => {
                                addQuestion(index)
                            }} className="btn text-4xl self-center text-green-500"><BsPlusCircleFill /></div>
                        }</div>
                        <div className="col-span-1 self-center">
                            <div onClick={() => moveQuestionUp(index)} className="text-4xl self-center text-blue-500">↑</div>
                            <div onClick={() => moveQuestionDown(index)} className="text-4xl self-center text-blue-500">↓</div>
                        </div>

                    </Grid12>
                }
                {
                    currentQuestion.type === "range" &&
                    <Grid12>
                        <AccordionComp key={index} title={`${currentQuestion.question} (range question)`} className={`bg-gray-100 my-1 col-span-10 ${currentQuestion.selected && 'bg-green-200'}`}>
                            <P>
                                <strong>Description: </strong>{currentQuestion.description} <br />
                                <strong>Minimum value: </strong>{currentQuestion.min} <br />
                                <strong>Maximum value: </strong>{currentQuestion.max} <br />
                            </P>
                            <hr />
                            <P>
                                <small>

                                    <strong>Created: </strong>{currentQuestion.createdAt && currentQuestion.createdAt.toString()} <br />
                                    <strong>Type: </strong>{currentQuestion.type}<br />
                                    <strong>Creator: </strong> {currentQuestion.creator && currentQuestion.creator} <br />
                                </small>
                            </P>
                        </AccordionComp>
                        <div className="col-span-1 self-center">{
                            currentQuestion.selected ? <div onClick={() => {
                                removeQuestion(index)
                            }} className="btn text-4xl self-center text-red-500"><BsTrashFill /></div> : <div onClick={() => {
                                addQuestion(index)
                            }} className="btn text-4xl self-center text-green-500"><BsPlusCircleFill /></div>
                        }</div>
                        <div className="col-span-1 self-center">
                            <div onClick={() => moveQuestionUp(index)} className="text-4xl self-center text-blue-500">↑</div>
                            <div onClick={() => moveQuestionDown(index)} className="text-4xl self-center text-blue-500">↓</div>
                        </div>
                    </Grid12>
                }
            </>)
            }
        </>
    )
}

export default QuestionsPicker