import { useState, useEffect } from 'react'
import { Section, H1, Grid12, InfoBox, BtnCreate, H2 } from '../../components/StyledComponents'
import { IBidirectionalTemplate } from '../../interface/Bidirectional/interface';
import { BidirectionalTemplateEmpty } from '../../interface/Bidirectional/empty';
import AppraisalModal from '../../components/AppraisalModal';
import Template from './components/Template';
import TemplateList from './TemplateList';
const TemplateCreate = () => {
    const [showModal, setShowModal] = useState<boolean>(false); //create modal
    const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false); //update modal
    const [templateToBeUpdated, setTemplateToBeUpdated] = useState<IBidirectionalTemplate | null>(null)
    const [refetchTemplates, setRefetchTemplates] = useState<boolean>(false);
    const [emptyTemplateStateObject, setEmptyTemplateStateObject] = useState<IBidirectionalTemplate>(BidirectionalTemplateEmpty)


    useEffect(() => {
        if (showModal) {
            setEmptyTemplateStateObject(BidirectionalTemplateEmpty)
        }
    }, [showModal])

    return (
        <Section>
            <H1>Bidirectional Templates - management</H1>
            <Grid12>
                <InfoBox className='col-span-12 md:col-span-10'>
                    <p>
                        Here you can create templates for the bidirectional appraisal.
                    </p>
                </InfoBox>
                <BtnCreate onClick={() => { setShowModal(!showModal) }} className='col-span-12 md:col-span-2'>Create Template</BtnCreate>
            </Grid12>
            <H2 className='my-2'>List of All templates</H2>
            <Grid12>
                <TemplateList setRefetch={() => { setRefetchTemplates(!refetchTemplates) }} refetch={refetchTemplates} setUpdate={(template: IBidirectionalTemplate) => {
                    setTemplateToBeUpdated(template);
                    setShowUpdateModal(!showUpdateModal);
                }} />
            </Grid12>
            {/* MODAL FOR CREATE A TEMPlATE */}
            <AppraisalModal title='Create a template' show={showModal} handleClose={() => {
                setShowModal(!showModal)
            }}>
                <Template template={emptyTemplateStateObject} setTemplate={setEmptyTemplateStateObject} refreshTemplates={() => { setRefetchTemplates(!refetchTemplates); setShowModal(false) }} />
            </AppraisalModal>
            {
                // MODAL FOR UPDATE A TEMPLATE
                templateToBeUpdated && <AppraisalModal title='Update a template' show={showUpdateModal} handleClose={() => {
                    setTemplateToBeUpdated(null)
                    setShowUpdateModal(!showUpdateModal)
                }}>

                    <Template refreshTemplates={() => {
                        setRefetchTemplates(!refetchTemplates)
                        setShowUpdateModal(!showUpdateModal)
                    }
                    } template={templateToBeUpdated as IBidirectionalTemplate} setTemplate={(t: IBidirectionalTemplate) => {
                        setTemplateToBeUpdated(t)
                    }} />
                </AppraisalModal>
            }


        </Section>
    )
}

export default TemplateCreate